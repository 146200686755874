import React from 'react'

import './ProfileSidebar.scss'

const StepCompletedImg = (
  <svg
    width="34px"
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>step complete</title>
    <defs>
      <circle id="path-1" cx="16" cy="16" r="16" />
    </defs>
    <g
      id="Slice-Sheet"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Quantum-Slice-Sheet_web"
        transform="translate(-551.000000, -113.000000)"
      >
        <g id="step-complete" transform="translate(552.000000, 114.000000)">
          <g id="Oval">
            <use fill="#9EDFAD" fillRule="evenodd" />
            <use strokeOpacity="0.05" stroke="#000000" strokeWidth="1" />
            <circle
              stroke="#9EDFAD"
              strokeWidth="1.5"
              cx="16"
              cy="16"
              r="15.25"
            />
          </g>
          <polygon
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
            points="14.0824177 21.948813 8.6 16.6833775 10.8574661 14.515257 14.0824177 17.612572 20.5323209 11.417942 22.7897871 13.5860625"
          />
        </g>
      </g>
    </g>
  </svg>
)

const QFinishedBlock = (title: string) => (
  <div className="cart-summary finished" key={title}>
    <div className="Block">{title}</div>
    <div className="index">
      {StepCompletedImg}
    </div>
  </div>
)

export default QFinishedBlock
