export enum COLORS {
  COMPLETION_LOW = '#ef471f',
  COMPLETION_MEDIUM = '#ff9d3b',
  COMPLETION_FULL = '#5cc073'
}

export const chartColors = [
  '#8FCBA1',
  '#63A6C8',
  '#F7A57A',
  '#A0DCFF',
  '#F9E3A4',
  '#F0A9AB',
  '#B489C5',
  '#5CC073',
  '#60676A',
  '#8FCBA2',
  '#63A6C5',
  '#F7A57B',
  '#A0DCAD',
  '#F9E3A5',
  '#F0A9AA',
  '#B489C8',
  '#5CC073',
  '#606400',
  '#8FCBA1',
  '#63A6C8',
  '#F7A57A',
  '#A0DCFF',
  '#F9E3A4',
  '#F0A9AB',
  '#B489C5',
  '#5CC073',
  '#60676A',
  '#8FCBA2',
  '#63A6C5',
  '#F7A57B',
  '#A0DCAD',
  '#F9E3A5',
  '#F0A9AA',
  '#B489C8',
  '#5CC073',
  '#606400'
]
