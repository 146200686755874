import { abbreviateNumber, getLocaleCost } from '../../helpers/number'
import React from 'react'

export const CustomizedLabel = ({ payload, x, y, width, height, value }) => {
  const text = abbreviateNumber(value)
  return (
    <text
      x={x + width / 2}
      y={y}
      dy={-4}
      style={{ fontSize: '13px', fontWeight: 600, fill: '#1E252B' }}
      textAnchor="middle"
    >
      {text}
    </text>
  )
}
