import React from 'react'

import './ProfileSidebar.scss'

const StepErrorImg = (
  <svg
    width="34px"
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>step error</title>
    <defs>
      <circle id="path-1" cx="16" cy="16" r="16" />
    </defs>
    <g
      id="Slice-Sheet"
      stroke="none"
      strokeWidth="1"
      fill="#f6b5a5"
      fillRule="evenodd"
    >
      <g
        id="Quantum-Slice-Sheet_web"
        transform="translate(-635.000000, -113.000000)"
      >
        <g id="step-error" transform="translate(636.000000, 114.000000)">
          <g id="Oval">
            <use fill="#f6b5a5" fillRule="evenodd" />
            <use strokeOpacity="0.05" stroke="#000000" strokeWidth="1" />
            <circle
              stroke="#FFA792"
              strokeWidth="1.5"
              cx="16"
              cy="16"
              r="15.25"
            />
          </g>
          <path
            d="M16.69,18.058 L17.014,9.454 L14.332,9.454 L14.638,18.058 L16.69,18.058 Z M17.068,22 L17.068,19.444 L14.26,19.444 L14.26,22 L17.068,22 Z"
            id="!"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)

const QUnfinishedBlock = (title: string) => (
  <div className="cart-summary unfinished" key={title}>
    <div className="Block">{title}</div>
    <div className="index">{StepErrorImg}</div>
  </div>
)

export default QUnfinishedBlock
