import React from 'react'

import { Select } from 'antd'
import './styles.scss'

const selectArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
    />
  </svg>
)

const QSelect = ({
  className = '',
  getPopupContainer = (node: any) => node.parentNode,
  ...props
}) => {
  return (
    <Select
      className={`qu-select ${className}`}
      dropdownClassName="qu-select"
      suffixIcon={selectArrow || props.selectArrow}
      getPopupContainer={getPopupContainer}
      {...props}
    />
  )
}
export default QSelect
