import React from 'react'
import { Row, Col, Upload, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { IconUpload } from '../Icons/IconsCommon'

import StandardFileView from './StandardFileView'
import AlternativeFileView from './AlternativeFileView'
import QButton from '../Buttons/QButton'

import './styles.scss'

export interface Props {
  className?: string
  helpText?: string
  heading?: string
  caption?: any
  isUploading?: boolean
  isRemoving?: boolean
  dragAndDrop?: boolean
  viewOnly?: boolean
  viewOnlyText?: string
  viewText?: string
  removeText?: string
  multiple?: boolean
  alternativeFileView?: boolean
  showRemoveButton?: boolean
  showPreview?: boolean
  disableView?: boolean
  disableRemove?: boolean
  maxCount?: number
  fileName?: string
  image?: any
  fileList?: object[]
  onView?: (file: any) => void
  disabled?: boolean
}

const QFileUploader = ({
  className = '',
  helpText,
  heading = 'Upload Image',
  caption = [
    '800px by 800px image size recommended.',
    'Max file size 5MB in JPG or PNG format.'
  ],
  fileList = [],
  fileName = '',
  isUploading = false,
  isRemoving = false,
  dragAndDrop = true,
  viewOnly = false,
  viewOnlyText = 'View',
  viewText = 'View',
  removeText = 'Remove',
  alternativeFileView = false,
  showRemoveButton = true,
  showPreview = true,
  disableView = false,
  disableRemove = false,
  disabled = false,
  image = null,
  onView = () => { },
  ...props
}: Props) => {
  const handleView = (e: React.MouseEvent, file: any) => {
    e.stopPropagation()

    if (disableView) {
      return
    }

    onView(file)
  }

  const handleRemove = (e: any, file?: any) => {
    e.stopPropagation()

    if (isProcessing || disableRemove) {
      return
    }

    // @ts-ignore
    props.onRemove && props.onRemove(file)
  }

  const isProcessing = isUploading || isRemoving
  const processingText = isUploading
    ? 'Uploading'
    : isRemoving
      ? 'Removing'
      : ''

  const DragAndDropUpload = (
    <Upload.Dragger
      className={`qu-uploader form-uploader ${className} ${image ? 'qu-uploader-single' : ''
        }`}
      disabled={disabled || isProcessing}
      {...props}
    >
      {image?.url ? (
        <div className="single-image_container">
          {image.url.includes('.webm') ||
            image.url.includes('.mp4') ||
            image.url.includes('.ogg') ? (
            <video width="178" height="100" controls>
              <source
                src={image.url}
                type={`video/${image.url.split('.')[image.url.split('.').length - 1]
                  }`}
              />
              Your browser does not support HTML video.
            </video>
          ) : image.url.includes('.pdf') ? (
            <img className="single-image" src={'https://quantum-files-bucket-new.s3.me-south-1.amazonaws.com/briefs_product_images/3832/1702199555443-pdf.png'} alt="" />
          ):(
            <img className="single-image" src={image.url} alt="" />
          )}
          <div className="details_wrapper">
            <div className="title text-truncate">{image?.name ?? ''}</div>
            <div onClick={handleRemove} className="remove">
              Remove
            </div>
          </div>
        </div>
      ) : (
        <Row align="middle">
          <Col>
            {isProcessing ? (
              <LoadingOutlined className="mr-30" />
            ) : (
              <IconUpload className="form-uploader__icon" />
            )}
          </Col>
          <Col>
            <div className="form-uploader__heading">
              {isProcessing ? processingText : heading}
            </div>
            <div className="form-uploader__caption">
              {caption.map((item, i) => (
                <div key={i}>{item}</div>
              ))}
            </div>
          </Col>
        </Row>
      )}
      {helpText && <div className="qu-uploader-hint">{helpText}</div>}
    </Upload.Dragger>
  )

  const NonDragAndDropUpload = (
    <Upload
      className={`qu-uploader ${className}`}
      disabled={disabled || isProcessing}
      {...props}
    >
      <div>
        {viewOnly && !fileList.length && (
          <span
            onClick={(event: React.MouseEvent<HTMLSpanElement>) =>
              event.stopPropagation()
            }
          >
            -
          </span>
        )}

        {!viewOnly && fileList.length < props.maxCount && (
          <Tooltip
            overlayClassName="qu-tooltip"
            placement="top"
            title={caption.map((item, i) => (
              <div key={i}>&#8226; {item}</div>
            ))}
          >
            <QButton
              className="qu-button-light-blue upload-button"
              icon={<IconUpload />}
              size="middle"
              disabled={disabled || isProcessing}
            >
              <span className="ml-5">
                {isProcessing
                  ? `${processingText} ${fileName}...`
                  : `Upload ${fileName}`}
              </span>
            </QButton>
          </Tooltip>
        )}

        {fileList.map((file: any, index, arr) => {
          const isLastFile = index === arr.length - 1
          return alternativeFileView ? (
            <AlternativeFileView
              key={index}
              fileName={fileName}
              viewOnly={viewOnly}
              viewOnlyText={viewOnlyText}
              disabled={disabled}
              disableView={disableView}
              showRemoveButton={showRemoveButton}
              onView={(event) => handleView(event, file)}
              onRemove={(event) => handleRemove(event, file)}
            />
          ) : (
            <StandardFileView
              key={index}
              className={isLastFile && 'no-border'}
              file={file}
              viewOnly={viewOnly}
              showPreview={showPreview}
              disableView={disableView}
              showRemoveButton={showRemoveButton}
              viewText={viewText}
              removeText={removeText}
              onView={(event) => handleView(event, file)}
              onRemove={(event) => handleRemove(event, file)}
            />
          )
        })}
      </div>
    </Upload>
  )

  return <div>{dragAndDrop ? DragAndDropUpload : NonDragAndDropUpload}</div>
}
export default QFileUploader
