import React, { useCallback, useReducer, useState } from 'react'
import { Collapse, Modal } from 'antd'
import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons'

import QButton from '../Buttons/QButton'
import { IconClose, IconEdit, IconTrash } from '../Icons/IconsCommon'
import './styles.scss'

const QTreeCollapse = ({ isModalOpen = false, teamMembersList, loggedInUserDetails, submitDeleteDepartmentOrMember, editDepartment, editMember, ownEmail }) => {
  const [visible, setVisible] = useState(isModalOpen)
  const [membersList, setMembersList] = useState(teamMembersList)
  const [deleteDepartmentOrMember, setDeleteDepartmentOrMember] = useState({});
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const toggleModal = useCallback(
    (value?: boolean): void => {
      setVisible(value === undefined ? !visible : value)
    },
    [setVisible, visible]
  )

  const onToggleModal = useCallback((): void => {
    toggleModal()
  }, [toggleModal])

  const onDeleteToggleModal = useCallback((data, DepartmentName): void => {
    setDeleteDepartmentOrMember({
      isDepartmentOrMember: 'member',
      id: data.id,
      name: data.firstName + ' ' + data.lastName,
      DepartmentName: DepartmentName
    })
    toggleModal()
  }, [toggleModal])

  const onDeleteDepartmentToggleModal = useCallback((data): void => {
    setDeleteDepartmentOrMember({
      isDepartmentOrMember: 'department',
      id: data.id,
      name: data.name
    });
    toggleModal()
  }, [toggleModal])

  const onEditDepartmentToggleModal = (data) => {
    editDepartment({
      id: data.id,
      name: data.name,
      locatePosition: data.locatePosition,
      locateDepartmentId: data.locateDepartmentId,
      isOwner: data.isOwner
    })
  }

  const onEditMemberToggleModal = (data, isOwner) => {
    editMember({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      partnerDepartmentId: data.partnerDepartmentId,
      partnerPermissionLevel: data.partnerPermissionLevel,
      isOwner: isOwner
    })
  }

  const onDeleteSubmitModal = () => {
    submitDeleteDepartmentOrMember(deleteDepartmentOrMember)
    toggleModal()
  }

  function collapseCEOHandleChange() {
    let membersData = membersList
    membersData.isOpen = !membersData.isOpen;
    setMembersList(membersData);
    forceUpdate()
  }

  function collapseMemberHandleChange(index) {
    let membersData = membersList
    membersData.subMembers[index].isOpen = !membersData.subMembers[index].isOpen;
    setMembersList(membersData);
    forceUpdate()
  }

  return (
    <div className="qu-tree">
      <ul className="qu-tree-branch">
        <li className="qu-tree-leaf level-0">
          <div className="leaf-wrapper">
            <Collapse
              className="qu-tree-item level-0"
              defaultActiveKey={[membersList.isOpen ? '-1' : '']}
              key={membersList.isOpen ? '-1' : ''}
              onChange={collapseCEOHandleChange}
              expandIcon={() => (membersList.isOpen ? <MinusSquareOutlined /> : <PlusSquareOutlined />)}
              ghost
            >
              <Collapse.Panel
                key='-1'
                className="qu-tree-list"
                header={
                  <div className="qu-tree-header">
                    <div className="qu-tree-label">{membersList.name}
                    </div>
                    <div>
                      {loggedInUserDetails.isOwner && loggedInUserDetails.partnerPermissionLevel == 'admin' &&
                        <button onClick={(e) => { e.stopPropagation(); onEditDepartmentToggleModal(membersList); }}>
                          <IconEdit className="CollapseEditIcon" />
                        </button>}
                    </div>
                  </div>
                }
              >
                <div className="qu-tree-row">
                  <div className="cell-name tree-name">{membersList.partnerMembers[0].firstName} {membersList.partnerMembers[0].lastName}</div>
                  <div className="cell-email">{membersList.partnerMembers[0].email}</div>
                  <div className="cell-controls">
                    <span className="cell-permission mr-20">{membersList.partnerMembers[0].partnerPermissionLevelLabel}</span>
                    {loggedInUserDetails.isOwner && loggedInUserDetails.partnerPermissionLevel == 'admin' &&
                      <button onClick={() => onEditMemberToggleModal(membersList.partnerMembers[0], membersList.isOwner)}>
                        <IconEdit className="CollapseEditIcon" />
                      </button>}
                  </div>
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
          <ul className="qu-tree-branch">
            {membersList.subMembers.map((ele, index) =>
              <li className="qu-tree-leaf" key={index}>
                <div className="leaf-wrapper">
                  <Collapse
                    className="qu-tree-item level-1"
                    defaultActiveKey={[ele.isOpen ? index : '']}
                    key={ele.isOpen ? index : ''}
                    onChange={() => collapseMemberHandleChange(index)}
                    expandIcon={() => (ele.isOpen ? <MinusSquareOutlined /> : <PlusSquareOutlined />)}
                    ghost
                  >
                    <Collapse.Panel
                      key={index}
                      className="qu-tree-list"
                      header={
                        <div className="qu-tree-header">
                          <div className="qu-tree-label">{ele.name} ({ele.partnerMembers.length})
                          </div>
                          <div>
                            <button onClick={(e) => { e.stopPropagation(); onEditDepartmentToggleModal(ele); }}>
                              <IconEdit className="CollapseEditIcon" />
                            </button>
                            <button onClick={(e) => { e.stopPropagation(); onDeleteDepartmentToggleModal(ele); }}>
                              <IconTrash className="CollapseDeleteIcon" />
                            </button>
                          </div>
                        </div>
                      }
                    >
                      {ele.partnerMembers.map((ele1, index1) =>
                        <div className="qu-tree-row" key={index + index1}>
                          <div className="cell-name tree-name">{ele1.firstName} {ele1.lastName}</div>
                          <div className="cell-email">{ele1.email}</div>
                          <div className="cell-controls">
                            <span className="cell-permission mr-20">{ele1.partnerPermissionLevelLabel}</span>
                            <button onClick={() => onEditMemberToggleModal(ele1, ele.isOwner)}>
                              <IconEdit className="CollapseEditIcon" />
                            </button>
                            {ownEmail != ele1.email && <button onClick={() => onDeleteToggleModal(ele1, ele.name)}>
                              <IconTrash className="CollapseDeleteIcon" />
                            </button>}
                          </div>
                        </div>
                      )}
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </li>
            )}
          </ul>
        </li>
      </ul>
      <Modal
        wrapClassName="qu-modal"
        closeIcon={<IconClose />}
        visible={visible}
        onCancel={onToggleModal}
        width={400}
        title={<h3>Are you sure?</h3>}
        footer={null}
      >
        <p className="mb-25">
          {
            deleteDepartmentOrMember['isDepartmentOrMember'] == 'department' ?
              <span>Do you want to delete the <strong>{deleteDepartmentOrMember['name']}</strong> department?</span> :
              <span>Do you want to delete the profile of <strong>{deleteDepartmentOrMember['name']}</strong> from <strong>{deleteDepartmentOrMember['DepartmentName']}</strong>?</span>
          }
        </p>
        <div className="grid-row grid-row--smg grid-row--jce">
          <div className="grid-col grid-col--auto">
            <QButton
              onClick={onDeleteSubmitModal}
              type="primary">
              Confirm
            </QButton>
          </div>
          <div className="grid-col grid-col--auto">
            <QButton className="qu-button-outline" onClick={onToggleModal}>
              Cancel
            </QButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default QTreeCollapse
