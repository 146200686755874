import React from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Text
} from 'recharts'

import './styles.scss'
import { abbreviateNumber, getLocaleCost } from '../../helpers/number'

const CustomTooltip = ({ active, payload }) => {
  const sum = payload
    .map((item) => item.value)
    .reduce((prev, curr) => prev + curr, 0)
  if (active) {
    return (
      <div className="qu-chart-tooltip">
        <ul className="tooltip-list">
          {payload.map((item, i) => {
            return (
              <li
                key={i}
                className={`tooltip-list-item dots ${item.name.toLowerCase()}`}
              >
                <span className="name">{item.name}</span>
                <span className="value">{getLocaleCost(item.value)}</span>
              </li>
            )
          })}
          <li className="tooltip-list-item total">
            <span className="name">Total</span>
            <span className="value">{getLocaleCost(sum)}</span>
          </li>
        </ul>
      </div>
    )
  }

  return null
}

export interface Props {
  className?: string
  height?: any
  colors?: object
  data?: any
  labels?: string[]
  xAngle?: number
  yAngle?: number
}

const QAreaChartStacked = ({
  className = '',
  height = 300,
  data,
  labels = ['Active', 'Completed', 'Clients', 'Partners'],
  xAngle = 0,
  yAngle = 0
}: Props) => {
  const CustomizedXAxisTick = ({ x, y, payload }) => {
    const value = !isNaN(payload.value)
      ? abbreviateNumber(payload.value)
      : payload.value
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          angle={xAngle}
          fill="#6E8590"
          className="qu-chart-axis-tick"
          width={100}
          x={0}
          y={0}
          verticalAnchor="start"
          textAnchor="middle"
        >
          {value}
        </Text>
      </g>
    )
  }

  const CustomizedYAxisTick = ({ x, y, payload }) => {
    const value = !isNaN(payload.value)
      ? abbreviateNumber(payload.value)
      : payload.value
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          angle={yAngle}
          fill="#6E8590"
          className="qu-chart-axis-tick"
          width={100}
          x={-4}
          y={0}
          verticalAnchor="middle"
          textAnchor="middle"
        >
          {value}
        </Text>
      </g>
    )
  }

  return (
    <div className={`qu-chart qu-chart-bar ${className}`}>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 40, left: 0, bottom: 0 }}
        >
          <XAxis dataKey="name" axisLine={false} tick={{ CustomizedXAxisTick }} />
          <YAxis tickLine={false} tick={CustomizedYAxisTick} />
          <Tooltip
            // @ts-ignore
            content={<CustomTooltip />}
          />
          <Area
            name={labels[0]}
            type="monotone"
            dataKey="firstLine"
            stackId="1"
            stroke="#8FCBA1"
            fill="#8FCBA1"
          />
          <Area
            name={labels[1]}
            type="monotone"
            dataKey="secondLine"
            stackId="1"
            stroke="#63A6C8"
            fill="#63A6C8"
          />
          {data?.length > 0 && labels?.length === 3 &&
            <Area
              name={labels[2]}
              type="monotone"
              dataKey="thirdLine"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8"
            />}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
export default QAreaChartStacked
