import React, { useState } from 'react'
import Form from 'antd/lib/form'
import clone from 'lodash/clone'
import QInput from '../Input/QInput'
import QButton from '../Buttons/QButton'
import QButtonEye from '../Buttons/QButtonEye'

import './styles.scss'

const REGEXES_PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{7,255}$/

const tips = {
  passRegexp:
    'Should contain at least 7 characters including a number, an uppercase letter and a lowercase letter',
  newEqualOld: 'The new password must be different from the old one!',
  newMustMatch: 'New passwords must match!'
}

const FIELD_NAMES = {
  NEW: '_new',
  OLD: 'old',
  REPEAT: 'repeat'
}

export interface IPasswordsTypeState {
  oldPassword: boolean
  newPassword: boolean
  confirmPassword: boolean
}

const UpdatePasswordForm = ({ onSubmit }) => {
  const [form] = Form.useForm()

  const [passwordsTypeState, setPasswordsState] = useState<IPasswordsTypeState>(
    {
      oldPassword: false,
      newPassword: false,
      confirmPassword: false
    }
  )

  const handlePasswordVisibility = (field: string) => () => {
    const newState = clone(passwordsTypeState)
    newState[field] = !newState[field]
    setPasswordsState(newState)
  }

  const getPasswordInputType = (field: string) => {
    return passwordsTypeState[field] ? 'text' : 'password'
  }

  const onFinish = () => {
    const formData = form.getFieldsValue()
    form.validateFields().then(() => {
      onSubmit(formData)
    })
  }

  const validateNewPassword = (rule, value, callback) => {
    const { old, repeat } = form.getFieldsValue()
    if (value === old) {
      callback(tips.newEqualOld)
    } else if (value !== repeat && form.isFieldTouched(FIELD_NAMES.REPEAT)) {
      callback(tips.newMustMatch)
    } else {
      callback()
    }
  }

  const validateRepeatPassword = (rule, value, callback) => {
    const { old, _new } = form.getFieldsValue()
    if (value === old) {
      callback(tips.newEqualOld)
    } else if (value !== _new && form.isFieldTouched(FIELD_NAMES.NEW)) {
      callback(tips.newMustMatch)
    } else {
      callback()
    }
  }

  return (
    <div className="container">
      <div className="auth-form">
        <div className="p-40">
          <h2>Change your password</h2>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="q-form-item-wrapper">
            <Form.Item
              label="Old password"
              name={FIELD_NAMES.OLD}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  pattern: REGEXES_PASSWORD,
                  message: tips.passRegexp,
                  validateTrigger: ['onChange', 'onBlur', 'onSubmit']
                }
              ]}
            >
              <QInput
                size="large"
                placeholder="Write your old password"
                style={{ paddingRight: 50 }}
                type={getPasswordInputType('oldPassword')}
              />
            </Form.Item>
            <QButtonEye
              onClick={handlePasswordVisibility('oldPassword')}
              isVisible={passwordsTypeState.oldPassword}
            />
          </div>
          <div className="q-form-item-wrapper">
            <Form.Item
              label="New password"
              name={FIELD_NAMES.NEW}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  pattern: REGEXES_PASSWORD,
                  message: tips.passRegexp,
                  validateTrigger: ['onChange', 'onBlur', 'onSubmit']
                },
                {
                  required: true,
                  validator: validateNewPassword,
                  validateTrigger: ['onChange', 'onBlur', 'onSubmit']
                }
              ]}
            >
              <QInput
                size="large"
                placeholder="Write your new password"
                style={{ paddingRight: 50 }}
                type={getPasswordInputType('newPassword')}
              />
            </Form.Item>
            <QButtonEye
              onClick={handlePasswordVisibility('newPassword')}
              isVisible={passwordsTypeState.newPassword}
            />
          </div>
          <div className="q-form-item-wrapper">
            <Form.Item
              label="Repeat the new password"
              name={FIELD_NAMES.REPEAT}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  pattern: REGEXES_PASSWORD,
                  message: tips.passRegexp,
                  validateTrigger: ['onChange', 'onBlur', 'onSubmit']
                },
                {
                  required: true,
                  validator: validateRepeatPassword,
                  validateTrigger: ['onChange', 'onBlur', 'onSubmit']
                }
              ]}
            >
              <QInput
                size="large"
                placeholder="Repeat your new password"
                style={{ paddingRight: 50 }}
                type={getPasswordInputType('confirmPassword')}
              />
            </Form.Item>
            <QButtonEye
              onClick={handlePasswordVisibility('confirmPassword')}
              isVisible={passwordsTypeState.confirmPassword}
            />
          </div>
          <QButton className="mt-16" type="primary" block htmlType="submit">
            Change password
          </QButton>
        </Form>
      </div>
    </div>
  )
}

export default UpdatePasswordForm
