import React from 'react'

import './styles.scss'

const QSummaryCart = ({ className = '', ...props }) => {
  return (
    <div className={`cart-summary ${className}`} {...props}>
      <a href="#" className="p p--xs tc--light cart-summary__edit">
        Edit
      </a>
      <div className="h6 cart-summary__heading">Services</div>
      <ul className="p p--sm cart-summary__ulist">
        <li>Product Sample Distribution</li>
        <li>Media-Buying</li>
      </ul>
      <hr className="cart-summary__divider" />
      <a href="#" className="p p--xs tc--light cart-summary__edit">
        Edit
      </a>
      <div className="h6 cart-summary__heading">Product Brief</div>
      <dl className="cart-summary__dlist">
        <dt>Name</dt>
        <dd>ABC Quick Snack</dd>
        <dt>Type</dt>
        <dd>Snack Food</dd>
        <dt>Format</dt>
        <dd>Sample</dd>
        <dt>Quantity</dt>
        <dd>1,000</dd>
        <dt>Des. Start</dt>
        <dd>May 1, 2020</dd>
        <dt>Duration</dt>
        <dd>3 Weeks</dd>
      </dl>
      <hr className="cart-summary__divider" />
      <a href="#" className="p p--xs tc--light cart-summary__edit">
        Edit
      </a>
      <div className="h6 cart-summary__heading">Media Buying</div>
      <dl className="cart-summary__dlist">
        <dt>Type</dt>
        <dd>Home Page Banners In App Banners</dd>
      </dl>
      <hr className="cart-summary__divider" />
      <a href="#" className="p p--xs tc--light cart-summary__edit">
        Edit
      </a>
      <div className="h6 cart-summary__heading">Targeting</div>
      <dl className="cart-summary__dlist">
        <dt>Ages</dt>
        <dd>Children, Middle</dd>
        <dt>Segments</dt>
        <dd>Students, Mother &amp; Infants</dd>
        <dt>Gender</dt>
        <dd>Female</dd>
        <dt>Countries</dt>
        <dd>Saudi Arabia, Kuwait</dd>
        <dt>Cities</dt>
        <dd>Jeddha, Riyadh</dd>
        <dt>Objective</dt>
        <dd>Product Feedback</dd>
      </dl>
      <div className="cart-summary__reach">
        <p className="p p--sm mb-0 tc--light">Potential Audience Reach</p>
        <div className="h3 cart-summary__value">189,000,000</div>
      </div>
    </div>
  )
}
export default QSummaryCart
