import React from 'react'

import { Table } from 'antd'

import './styles.scss'
import QSelect from '../Select/QSelect'

const selectOptions = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'View & Edit',
    value: 'edit'
  },
  {
    label: 'View',
    value: 'view'
  },
  {
    label: 'Some loooong name',
    value: 'loooong'
  }
]

const columns = [
  {
    dataIndex: 'name',
    key: 'name'
  },
  {
    dataIndex: 'email',
    key: 'email'
  },
  {
    dataIndex: 'select',
    key: 'select',
    render: (select) => (
      <div className="grid-row grid-row--jce">
        <QSelect
          showArrow
          defaultValue={selectOptions[0].value}
          options={selectOptions}
          size="large"
          style={{ width: 170 }}
        />
      </div>
    )
  }
]

const data = [
  {
    key: 1,
    name: 'CEO',
    description: 'description description description',
    children: [
      {
        key: 11,
        name: 'Sales Manager (2)',
        children: [
          {
            key: 121,
            name: 'Jimmy Brown 1',
            email: 'some01@email.com'
          },
          {
            key: 121,
            name: 'Jimmy Brown 2',
            email: 'some01@email.com'
          }
        ]
      },
      {
        key: 12,
        name: 'Marketing Manager (1)',
        children: [
          {
            key: 121,
            name: 'Jimmy Brown',
            email: 'some01@email.com'
          }
        ]
      }
    ]
  }
]

const QTree = ({ className = '' }) => {
  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      showHeader={false}
      pagination={false}
      defaultExpandAllRows
    />
  )
}
export default QTree
