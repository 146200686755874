import React from 'react'
import { IconEye } from '../Icons/IconsCommon'

import './styles.scss'

export interface IProps {
  className?: string
  isVisible?: boolean
  onClick?: () => void
}

const QButtonEye = ({
  className = '',
  isVisible = false,
  onClick = () => null
}: IProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`button-eye ${className}`}
    >
      <IconEye isVisible={isVisible} />
    </button>
  )
}

export default QButtonEye
