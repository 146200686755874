import React from 'react'
import './styles.scss'

export interface Props {
  title?: string
  subtitle?: string
  children?: any
  className?: string
}

const BriefSummary = ({ title, subtitle, children, className = '' }: Props) => (
  <div className={`BS ${className}`}>
    {title && (
      <div className="BS__header">
        <h3>{title}</h3>
        {subtitle && <span className="BS__subtitle">{subtitle}</span>}
      </div>
    )}
    <div className="BS__body">{children}</div>
  </div>
)

export default BriefSummary

