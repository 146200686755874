import React from 'react'
import { Tooltip } from 'antd'

import {
  IconVisitors,
  IconViewers,
  IconFacebook,
  IconInstagram,
  IconTwitter,
  IconLinkedIn,
  IconYoutube,
  PackageIcon
} from '../Icons/IconsCommon'

import './styles.scss'

export interface Props {
  list: any
}

const SocialList = ({ list }: Props) => (
  <div className="social-list">
    {list.map((item) => {
      let iconData
      switch (item.name) {
        case 'Customers':
          iconData = <IconVisitors />
          break

        case 'Impressions':
          iconData = <IconViewers />
          break

        case 'Facebook':
          iconData = <IconFacebook />
          break

        case 'Instagram':
          iconData = <IconInstagram />
          break

        case 'Twitter':
          iconData = <IconTwitter />
          break

        case 'LinkedIn':
          iconData = <IconLinkedIn />
          break

        case 'Youtube':
          iconData = <IconYoutube />
          break

        case 'Monthly Order Volume':
          iconData = <PackageIcon />
          break

        default:
          break
      }
      return (
        <div key={item.name} className="social-list__item">
          <Tooltip
            overlayClassName="qu-tooltip"
            placement="bottom"
            title={item.name}
          >
            <span onClick={() => item.redirectURL && window.open(item.redirectURL, '_blank')}>
              <i className="i">{iconData}</i>
              {Number(item.counter) > 0 || item.counter !== '0' ? item.counter : ''}
            </span>
          </Tooltip>
        </div>
      )
    })}
  </div>
)

export default SocialList
