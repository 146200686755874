import React from 'react'
import './styles.scss'
import SocialList from '../SocialList/SocialList'
import QPricePopover from '../PricePopover/QPricePopover'

const packageIcon = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        x="-2.1%"
        y="-1.4%"
        width="104.2%"
        height="103.9%"
        filterUnits="objectBoundingBox"
        id="b"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0772508741 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="a" x="0" y="0" width="330" height="360" rx="10" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-13 -292)">
        <use fill="#000" filter="url(#b)" href="#a" />
        <rect
          strokeOpacity=".05"
          stroke="#000"
          fill="#FFF"
          x=".5"
          y=".5"
          width="329"
          height="359"
          rx="10"
        />
      </g>
      <path
        d="M19.936 4.426L16.47 1.121A.37.37 0 0016.185 1H4.95a.37.37 0 00-.286.121L1.2 4.426A.782.782 0 001 4.95v14.779c0 .246.164.406.406.406h18.326c.247 0 .407-.164.407-.406V4.91a.762.762 0 00-.203-.485zm-8.962-2.613h5.051l2.688 2.566h-7.74V1.813h0zm-5.864 0h5.051v2.566H2.422L5.11 1.813zm-3.297 8.43h3.5v4.07h-3.5v-4.07h0zm17.51 4.07h-3.501v-4.07h3.5v4.07zm0-4.886h-3.911c-.246 0-.407.164-.407.406v4.887c0 .246.164.407.407.407h3.91v4.195H1.812v-4.195h3.911a.437.437 0 00.406-.407V9.833c0-.246-.164-.406-.406-.406h-3.91V5.192h17.51v4.235z"
        fill="#1E252B"
        fillRule="nonzero"
        opacity=".5"
        stroke="#1E252B"
        strokeWidth=".1"
      />
    </g>
  </svg>
)
const deviceIcon = (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        x="-2.1%"
        y="-1.4%"
        width="104.2%"
        height="103.9%"
        filterUnits="objectBoundingBox"
        id="b"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0772508741 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="a" x="0" y="0" width="330" height="360" rx="10" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-89 -293)">
        <use fill="#000" filter="url(#b)" href="#a" />
        <rect
          strokeOpacity=".05"
          stroke="#000"
          fill="#FFF"
          x=".5"
          y=".5"
          width="329"
          height="359"
          rx="10"
        />
      </g>
      <path
        d="M22.395 0c.9038 0 1.595.6912 1.595 1.595v12.759c0 .9039-.6912 1.595-1.595 1.595h-6.3795v1.5949c0 .319.2126.5316.5316.5316h.5316c.319 0 .5316.2127.5316.5316 0 .319-.2126.5316-.5316.5316H9.636c-.319 0-.5316-.2127-.5316-.5316 0-.319.2126-.5316.5316-.5316h.5316c.319 0 .5316-.2126.5316-.5316v-1.595H9.6358c-.319 0-.5316-.2126-.5316-.5315 0-.319.2126-.5317.5316-.5317H22.395c.319 0 .5316-.2126.5316-.5316V1.595c0-.3189-.2126-.5316-.5316-.5316H4.3195c-.319 0-.5316.2127-.5316.5316v5.3163c0 .319-.2127.5316-.5316.5316-.319 0-.5317-.2126-.5317-.5316V1.595C2.7246.691 3.4157 0 4.3196 0zM6.446 8.5061c.9038 0 1.595.6912 1.595 1.595v7.4428c0 .9037-.6912 1.5949-1.595 1.5949H2.193c-.9038 0-1.595-.6912-1.595-1.595V10.101c0-.9037.6912-1.5949 1.595-1.5949zm8.5061 7.443h-3.1898v1.5948c0 .2126-.0531.3721-.1063.5316h3.4024c-.0531-.1595-.1063-.319-.1063-.5316V15.949zm-8.506-6.3797H2.193c-.319 0-.5316.2126-.5316.5316v7.4429c0 .319.2126.5316.5316.5316h4.253c.319 0 .5317-.2126.5317-.5316V10.101c0-.319-.2127-.5316-.5317-.5316zm-2.1266 6.9112a.5316.5316 0 110 1.0633.5316.5316 0 010-1.0633zm9.0378-3.1898a.5316.5316 0 110 1.0633.5316.5316 0 010-1.0633z"
        fill="#1E252B"
        fillRule="nonzero"
        opacity=".5"
      />
    </g>
  </svg>
)
const giftIcon = (
  <svg
    width="26"
    height="21"
    viewBox="0 0 26 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        x="-2.1%"
        y="-1.4%"
        width="104.2%"
        height="103.9%"
        filterUnits="objectBoundingBox"
        id="b"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0772508741 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="a" x="0" y="0" width="330" height="360" rx="10" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-12 -289)">
        <use fill="#000" filter="url(#b)" href="#a" />
        <rect
          strokeOpacity=".05"
          stroke="#000"
          fill="#FFF"
          x=".5"
          y=".5"
          width="329"
          height="359"
          rx="10"
        />
      </g>
      <g opacity=".5" stroke="#1E252B">
        <path d="M22.5 9.5v8h-3M6.5 14.5v-5" />
        <path
          d="M8.5 3.5c0-1.1.9-2 2-2 3 0 4 4 4 4h-4c-1.1 0-2-.9-2-2zM20.5 3.5c0-1.1-.9-2-2-2-3 0-4 4-4 4h4c1.1 0 2-.9 2-2zM4.5 5.5h20v4h-20z"
          strokeLinecap="square"
        />
        <path d="M15.6 17.5h4.9c2.4 0 3 2 3 2l-10.6 3.8c-.8.2-1.7.2-2.5-.1l-7.9-3.2937V14.5h4c1.6 0 3.1.7 4 2h3c1.5 0 3 1.5 3 3h-7" />
      </g>
    </g>
  </svg>
)
const checkedCard = (
  <svg
    viewBox="0 0 18 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M13.866 12.076c.3-.02.555-.206.673-.468l2.39-5.313a.797.797 0 00-.725-1.123h-11.8l-.465-1.326-.002.001a.794.794 0 00-.75-.535H.797a.797.797 0 100 1.594h1.825l3.068 8.766H4.25a1.594 1.594 0 101.594 1.593h7.437a1.594 1.594 0 101.594-1.593H7.379l-.398-1.137zm-7.431-1.101l-1.473-4.21H14.97l-1.688 3.753z" />
      <path
        d="M15.223.501c.325-.015.656.094.916.33a1.4 1.4 0 01.431.944 1.32 1.32 0 01-.352.955L11.22 8.128c-.281.304-.66.466-1.043.48a1.495 1.495 0 01-1.084-.405l-2.81-2.81a1.304 1.304 0 010-1.85 1.304 1.304 0 011.85 0l1.997 1.998L14.343.915c.236-.26.555-.398.88-.414z"
        stroke="#D15E28"
      />
    </g>
  </svg>
)
const cardDefault = (
  <svg height="173" viewBox="0 0 330 173" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path id="a" d="M0 0h330v173H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <use fill="#d9d9d9" href="#a" />
      <g fill="#444" fillRule="nonzero" opacity=".08" mask="url(#b)">
        <path d="M207 41h-66c-2.2091 0-4 1.7909-4 4v78c0 2.2091 1.7909 4 4 4h8c0-9.9411 8.0589-18 18-18s18 8.0589 18 18h24c1.1046 0 2-.8954 2-2V45c0-2.2091-1.7909-4-4-4z" />
        <path d="M167 141c-7.732 0-14-6.268-14-14s6.268-14 14-14 14 6.268 14 14c-.01 7.7279-6.2721 13.99-14 14zm0-20c-3.3137 0-6 2.6863-6 6s2.6863 6 6 6 6-2.6863 6-6-2.6863-6-6-6zM231 141c-7.732 0-14-6.268-14-14s6.268-14 14-14 14 6.268 14 14c-.01 7.7279-6.2721 13.99-14 14zm0-20c-3.3137 0-6 2.6863-6 6s2.6863 6 6 6 6-2.6863 6-6-2.6863-6-6-6z" />
        <path d="M260.8 90.106l-14-28A2 2 0 00245 61h-28c-1.1046 0-2 .8954-2 2v55.778c3.8035-7.3799 12.1611-11.201 20.2309-9.2495 8.0698 1.9514 13.758 9.1691 13.7691 17.4715h10c1.1046 0 2.0001-.8954 2.0001-2V91a2.0017 2.0017 0 00-.2001-.894zM221 89V67h21.528l11 22H221z" />
        <g>
          <path d="M126.614 68.211L119 62.289V49c0-.5523-.4477-1-1-1h-2c-.5523 0-1 .4477-1 1v13.289l-7.614 5.922A.9997.9997 0 00107 69v7c0 .5523.4477 1 1 1s1-.4477 1-1v-6.511l8-6.222 8 6.222V76c0 .5523.4477 1 1 1s1-.4477 1-1v-7a.9997.9997 0 00-.386-.789zM127 101H73c-2.2091 0-4 1.7909-4 4 0 2.2091 1.7909 4 4 4h54c2.2091 0 4-1.7909 4-4 0-2.2091-1.7909-4-4-4zm-54 6c-1.1046 0-2-.8954-2-2s.8954-2 2-2 2 .8954 2 2-.8954 2-2 2zm18 0c-1.1046 0-2-.8954-2-2s.8954-2 2-2 2 .8954 2 2-.8954 2-2 2zm18 0c-1.1046 0-2-.8954-2-2s.8954-2 2-2 2 .8954 2 2-.8954 2-2 2zm18 0c-1.1046 0-2-.8954-2-2s.8954-2 2-2 2 .8954 2 2-.8954 2-2 2zM100 73H74c-.5523 0-1 .4477-1 1v24c0 .5523.4477 1 1 1h26c.5523 0 1-.4477 1-1V74c0-.5523-.4477-1-1-1zm-9 8h-8c-.5523 0-1-.4477-1-1s.4477-1 1-1h8c.5523 0 1 .4477 1 1s-.4477 1-1 1zM126 81h-18c-.5523 0-1 .4477-1 1v16c0 .5523.4477 1 1 1h18c.5523 0 1-.4477 1-1V82c0-.5523-.4477-1-1-1zm-6 6h-6c-.5523 0-1-.4477-1-1s.4477-1 1-1h6c.5523 0 1 .4477 1 1s-.4477 1-1 1z" />
        </g>
      </g>
    </g>
  </svg>
)

export interface IPartnerCardService {
  name: string
  price: string
  type: string
  subtype?: string
}

export interface Props {
  cardType?: string
  preview?: boolean
  selected?: boolean
  headerImg?: string
  logo?: string
  name?: string
  url?: string
  description?: string
  social?: any
  servicesPackagePrice?: string|number
  servicesDevicePrice?: string|number
  servicesGiftPrice?: string
  servicesMatch?: number
  onClick?: (e: any, name?: string) => void
  eventDateMonth?: string
  eventDateDay?: string
  popupServices?: IPartnerCardService[]
  priceBlur?: string
  onMoreServicesClick?: (e: any, id?: number) => void
  mbMetricsPrices?: any
  hideDistributionPrice?: boolean
  hideMediaBuyingPrice?: boolean
}

const ProfileCard = ({
  cardType = 'ecommerce',
  eventDateMonth,
  eventDateDay,
  preview,
  selected,
  headerImg = 'https://via.placeholder.com/335x175',
  logo,
  name,
  url,
  description,
  social,
  servicesPackagePrice,
  servicesDevicePrice,
  servicesGiftPrice,
  servicesMatch,
  onClick,
  popupServices = [
    {
      name: "Product Sample",
      price: "890.81 SAR",
      type: "Distribution"
    },

    {
      name: "Printed Insert",
      price: "669.20 SAR",
      type: "Distribution"
    },
    {
      name: "Homepage banner (Web)",
      price: "5.33 SAR",
      subtype: "Display Ad",
      type: "Media Buying"
    },
    {
      name: "Push Notifications",
      price: "266.65 SAR",
      subtype: "Direct Communication",
      type: "Media Buying"
    }
  ],
  priceBlur,
  mbMetricsPrices,
  hideDistributionPrice,
  hideMediaBuyingPrice
}: Props) => {
  const getMatchClass = () => {
    if (servicesMatch > 100) {
      return 100
    }

    return Math.floor(servicesMatch / 10) * 10
  }
  const getSmallDigits = (value) =>
    value.toString().length > 6 ? 'small-text' : ''

  return (
    <div
      className={`ProfileCard ProfileCard-${cardType}
      ${selected ? 'selected' : ''}
    `}
      onClick={onClick}
    >
      {preview && <div className="ProfileCard__previewLabel">preview</div>}
      <div className="ProfileCard__thumb">
        {headerImg !== null ? (
          <img src={headerImg} alt="thumbnail" />
        ) : (
          cardDefault
        )}
        <div className="checked-icon">{checkedCard}</div>
      </div>
      <div className="ProfileCard__header">
        <div className="ProfileCard__logo">
          {logo && <img src={logo} alt={name} />}
        </div>
        {eventDateMonth && eventDateDay && (
          <div className="ProfileCard__logo">
            <div className="ProfileCard__date">
              <span className="ProfileCard__date-month">{eventDateMonth}</span>
              <span className="ProfileCard__date-day">{eventDateDay}</span>
            </div>
          </div>
        )}
        <div className="ProfileCard__header-info">
          {name && <span className="ProfileCard__name">{name}</span>}
          {url && <span className="ProfileCard__url">{url}</span>}
        </div>
      </div>
      <div className="ProfileCard__body">
        {description && (
          <div className="ProfileCard__description ProfileCard__description-text">
            {description}
          </div>
        )}
        <div className={`ProfileCard__meta ${mbMetricsPrices ? 'mbMetricsPricesSection' : ''}`}>
          <QPricePopover hideDistributionPrice={hideDistributionPrice} hideMediaBuyingPrice={hideMediaBuyingPrice}  popupServices={popupServices} onClick={onClick} priceBlur={priceBlur}>
            <div className="ProfileCard__services">
              {servicesPackagePrice && (
                <div
                  className={`ProfileCard__services-item ${priceBlur} ${getSmallDigits(
                    servicesPackagePrice
                  )}`}
                >
                  <i className="i">{packageIcon}</i>
                  {hideDistributionPrice?"":servicesPackagePrice}
                </div>
              )}
              {servicesDevicePrice && (
                <div
                  className={`ProfileCard__services-item ${priceBlur} ${getSmallDigits(
                    servicesDevicePrice
                  )}`}
                >
                  <i className="i">{deviceIcon}</i>
                  {hideMediaBuyingPrice?"":servicesDevicePrice}
                </div>
              )}
              {servicesGiftPrice && (
                <div
                  className={`ProfileCard__services-item ${priceBlur} ${getSmallDigits(
                    servicesGiftPrice
                  )}`}
                >
                  <i className="i">{giftIcon}</i>
                  {servicesGiftPrice}
                </div>
              )}
            </div>
          </QPricePopover>

          {mbMetricsPrices && <div className="ProfileCard__services" style={{ marginBottom: '12px' }}>
            <div className="ProfileCard__services-item metrics_section">CPC<br /><div className="metrics_pricing">{mbMetricsPrices.CPC}</div></div>
            <div className="vl"></div>
            <div className="ProfileCard__services-item metrics_section">CPM<br /><div className="metrics_pricing">{mbMetricsPrices.CPM}</div></div>
            <div className="vl"></div>
            <div className="ProfileCard__services-item metrics_section">CPR<br /><div className="metrics_pricing">{mbMetricsPrices.CPR}</div></div>
          </div>}

          <div className="ProfileCard__tags">
            {cardType && (
              <div className={`badge badge--${cardType}`}>{cardType}</div>
            )}
            {servicesMatch && (
              <div
                className={`badge badge--match badge--match-${getMatchClass()} ml-5`}
              >
                {servicesMatch.toFixed()}%
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="ProfileCard__footer">
        {social && <SocialList list={social} />}
      </div>
    </div>
  )
}

export default ProfileCard
