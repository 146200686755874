import React from 'react'

import './ProfileSidebar.scss'

const QDisabledBlock = (title: string, index: number) => (
  <div className="cart-summary disabled" key={title}>
    <div className="Block">{title}</div>
    <div className="index">{index}</div>
  </div>
)

export default QDisabledBlock
