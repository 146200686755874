import React from 'react'

import QButton from '../Buttons/QButton'

import './styles.scss'

export interface Props {
  title: string
  checked: boolean
  onChange: (item: any) => void
  onDelete: (item: any) => void
  onLoadImage?: (image: string) => void
  text?: string
  date?: string
  className?: string
  cardType?: string
  inputName: string
  cardImage?: string
  isPreview?: boolean
}

const QSelectImage = ({
  className = '',
  title = '',
  text = '',
  date = '',
  cardType = 'checkbox',
  inputName = 'card',
  cardImage = '',
  checked = false,
  onChange,
  onDelete,
  onLoadImage,
  isPreview = false
}: Props) => {
  return (
    <div className={`card card--selectable card-preview ${className}`}>
      <input
        checked={checked}
        onChange={onChange}
        type={cardType}
        name={inputName}
        className="card__input"
      />
      <div className="card__checkmark" />
      <div className="card__head card__head--43">
        <img className="card__image" src={cardImage} alt="" onLoad={() => { onLoadImage(cardImage) }} />
      </div>
      <div className="card__cont controls">
        <div className="card-info-wrapper">
          <div className="card-label text-truncate" title={title}>
            {title}
          </div>
          {text && (
            <p className="card-text tc--light text-truncate" title={text}>
              {text}
            </p>
          )}
          {date && <p className="card-date tc--light">{date}</p>}
        </div>
        {!isPreview && (
          <QButton className="qu-button-red" style={{ zIndex: 1 }} size="small" onClick={onDelete}>
            Delete
          </QButton>
        )}
      </div>
    </div>
  )
}
export default QSelectImage
