import React, { ReactElement, useState, useCallback } from 'react'
import { Drawer } from 'antd'

import './styles.scss'

const menuIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
    />
  </svg>
)

const closeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
    />
  </svg>
)

export interface Props {
  className?: string
  isMenuOpen?: boolean
  childrenTitle?: ReactElement | null
  childrenMenus?: ReactElement | null
  childrenSidenav?: ReactElement | null
}

const QPartnerHeader = ({
  className,
  isMenuOpen = false,
  childrenMenus,
  childrenTitle,
  childrenSidenav,
  ...props
}: Props) => {
  const [visible, setVisible] = useState(isMenuOpen)
  const toggleMenu = useCallback(
    (value?: boolean): void => {
      setVisible(value === undefined ? !visible : value)
    },
    [setVisible, visible]
  )
  const onClickToggle = useCallback((): void => {
    toggleMenu()
  }, [toggleMenu])

  return (
    <header className={`qu-header-partner ${className}`} {...props}>
      <div className="qu-header-left">
        <button className="menu-button" onClick={onClickToggle}>
          {menuIcon}
        </button>
        {childrenTitle}
      </div>
      <div className="qu-header-right">{childrenMenus}</div>
      <Drawer
        className="qu-drawer"
        placement="left"
        width={220}
        onClose={onClickToggle}
        visible={visible}
        closeIcon={closeIcon}
      >
        {childrenSidenav}
      </Drawer>
    </header>
  )
}

export default QPartnerHeader
