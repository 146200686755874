import React from 'react'

import './styles.scss'

const removeIcon = (
  <svg viewBox="0 0 14 14">
    <path
      d="m775.7 124.3c-.4-.4-1-.4-1.4 0l-5.3 5.3-5.3-5.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.3 5.3-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l5.3-5.3 5.3 5.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-5.3-5.3 5.3-5.3c.4-.4.4-1 0-1.4z"
      fill="#6e8590"
      transform="translate(-762 -124)"
    />
  </svg>
)

export interface Props {
  tagName: string
  className?: string
  onCancel?: (e: any, name?: string) => void
}

const QFilterTag = ({
  tagName = 'Filter Tag',
  className = '',
  onCancel,
  ...props
}: Props) => {
  return (
    <div className={`qu-filter-tag ${className}`} {...props}>
      {tagName}
      <span className="cancel" onClick={onCancel}>
        {removeIcon}
      </span>
    </div>
  )
}
export default QFilterTag
