import React from 'react'
import { Modal } from 'antd'

import { IconClose } from '../Icons/IconsCommon'

import './styles.scss'

export interface Props {
  className?: string
  visible: boolean
  children?: React.ReactNode
  onCancel: () => void
}

const MediaTypeModalLayout = ({
  children,
  visible,
  className,
  onCancel,
  ...props
}: Props) => (
  <div>
    <Modal
      wrapClassName={`qu-modal qu-modal-details qu-modal-media-types ${className}`}
      onCancel={onCancel}
      closeIcon={<IconClose />}
      visible={visible}
      footer={null}
      {...props}
    >
      {children}
    </Modal>
  </div>
)

export default MediaTypeModalLayout
