import React from 'react'

import './ProfileSidebar.scss'

const QCurrentBlock = (
  title: string,
  list: string[],
  completed: boolean[],
  index: number
) => {
  return (
    <div className="cart-summary current" key={title}>
      <div className="Block">{title}</div>
      <ul className="p p--sm cart-summary__ulist mb-0">
        {[...list].map((step, index) => {
          if (completed[index]) {
            return (
              <li key={index} className="completed">
                {step}
              </li>
            )
          } else {
            return (
              <li key={index} className="disabled">
                {step}
              </li>
            )
          }
        })}
      </ul>
      <div className="index">{index}</div>
    </div>
  )
}

export default QCurrentBlock
