import React, { ReactElement } from 'react'

import { Dropdown } from 'antd'
import './styles.scss'

const ellipsisIcon = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="128px"
    height="512px"
    viewBox="0 0 128 512"
  >
    <path d="M128,256c0,35.3-28.7,64-64,64S0,291.3,0,256s28.7-64,64-64S128,220.7,128,256z" />
    <path d="M128,64c0,35.3-28.7,64-64,64S0,99.3,0,64S28.7,0,64,0S128,28.7,128,64z" />
    <path d="M128,448c0,35.3-28.7,64-64,64S0,483.3,0,448s28.7-64,64-64S128,412.7,128,448z" />
  </svg>
)

const checkIcon = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <g>
      <g>
        <path
          d="M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.332,26.629,74.98,74.98C26.629,123.332,0,187.62,0,256
			s26.629,132.667,74.98,181.019C123.332,485.371,187.62,512,256,512s132.667-26.629,181.019-74.98
			C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M378.306,195.073L235.241,338.139
			c-2.929,2.929-6.768,4.393-10.606,4.393c-3.839,0-7.678-1.464-10.607-4.393l-80.334-80.333c-5.858-5.857-5.858-15.354,0-21.213
			c5.857-5.858,15.355-5.858,21.213,0l69.728,69.727l132.458-132.46c5.857-5.858,15.355-5.858,21.213,0
			C384.164,179.718,384.164,189.215,378.306,195.073z"
        />
      </g>
    </g>
  </svg>
)

export enum BRIEF_STATUS {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PENDING_APPROVAL = 'PENDING APPROVAL',
  REJECTED = 'REJECTED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  NO_RESPONSE = 'NO_RESPONSE',
  PAID = 'PAID'
}

export interface Props {
  cardList: any
  labelStatus?: any
  paymentStatus?: any
  menu?: (id: number, isRejection: boolean) => ReactElement
  onCardClick?: (id: any) => void
}

const QTableCardList = ({
  cardList,
  menu,
  labelStatus,
  paymentStatus,
  onCardClick = () => {}
}: Props) => {
  const rejectionStatuses = [
    BRIEF_STATUS.REJECTED,
    BRIEF_STATUS.PARTIALLY_REJECTED
  ]

  return (
    <div className="tc-list">
      {cardList.map((card) => {
        return (
          <div
            key={card.key}
            className="tc-list__card"
            onClick={() => onCardClick(card.status.id)}
          >
            <div className="tc-list__card-header">
              <div className="tc-list__card-thumb-wrap">
                <div className="tc-list__card-thumb">
                  <img src={card.thumb} alt={card.title} />
                </div>
              </div>
              <div className="tc-list__card-header-info">
                <h3 className="tc-list__card-title">
                  {card.product.title || 'Unnamed Brief'}
                </h3>
                <span className="tc-list__card-quantity">
                  {card.services.some(
                    (service) => service.title === 'Product Sample Distribution'
                  )
                    ? `${card.services[0].quantity} units`
                    : '-'}
                </span>
                {card.discountApplied && (
                  <div className="text-green line-height-16 font-size-12">
                    {card.discountApplied}
                  </div>
                )}
              </div>
              <div
                className="tc-list__card-uBar"
                onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                  event.stopPropagation()
                }
              >
                {menu && (
                  <Dropdown
                    overlay={() =>
                      menu(
                        card.status.id,
                        rejectionStatuses.includes(card.status.value)
                      )
                    }
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <div className="q-table_menu">{ellipsisIcon}</div>
                  </Dropdown>
                )}
              </div>
            </div>
            <div className="tc-list__sub-header">
              <div className="sub-header__status">
                <div className="sub-header__status-label">Brief status</div>
                <span
                  className={`status-label ${
                    labelStatus[card.status.value] &&
                    labelStatus[card.status.value].classStyle
                  }`}
                >
                  {labelStatus[card.status.value] &&
                    labelStatus[card.status.value].label}
                </span>
              </div>
              {card?.paymentStatus && (
                <div className="sub-header__status">
                  <div className="sub-header__status-label">Payment status</div>
                  <span
                    className={`status-label ${
                      paymentStatus[card?.paymentStatus?.value] &&
                      paymentStatus[card?.paymentStatus?.value].classStyle
                    }`}
                  >
                    {paymentStatus[card?.paymentStatus?.value] &&
                      paymentStatus[card?.paymentStatus?.value].label}
                  </span>
                </div>
              )}
            </div>
            <div className="tc-list__card-body">
              <div className="tc-list__card-services">
                {card.services.map((service) => {
                  return (
                    <div
                      key={service.title}
                      className="tc-list__card-services-item"
                    >
                      <div className="tc-list__card-services-icon-wrap">
                        <div className="tc-list__card-services-icon">
                          {checkIcon}
                        </div>
                      </div>
                      <div className="tc-list__card-services-info">
                        <span className="tc-list__card-services-title">
                          {service.title}
                        </span>
                        <span className="tc-list__card-services-meta">
                          {`${service.startDate} - ${service.duration}`}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default QTableCardList
