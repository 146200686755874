import React from 'react'
import './styles.scss'

export interface Props {
  headerLeft?: string | React.ReactNode
  headerRight?: string | React.ReactNode
  children?: string | React.ReactNode
}
const InfoCard = ({ headerLeft, headerRight, children, ...props }: Props) => (
  <div className="InfoCard">
    <div className="InfoCard__header">
      {
        headerLeft &&
        <div className="InfoCard__header-left">
          {headerLeft}
        </div>
      }
      {
        headerRight &&
        <div className="InfoCard__header-right">
          {headerRight}
        </div>
      }
    </div>
    <div className="InfoCard__body">
      {children}
    </div>
  </div>
)

export default InfoCard

