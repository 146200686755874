import React, { ReactElement } from 'react'
import { Avatar, Dropdown } from 'antd'

import './styles.scss'

export interface Props {
  menu: ReactElement | null
  profileName?: string
  profileAvatar?: string
  profileInitials?: string
  clientMobile?: boolean
  welcomeBackText?: string
}

const QUserMenu = ({
  menu,
  profileName,
  profileAvatar,
  profileInitials,
  clientMobile,
  welcomeBackText = "Welcome back",
  ...props
}: Props) => {

  const avatar = <img src={profileAvatar} />
  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={(node: any) => node.parentNode}
      {...props}
    >
      <div className="user-menu">
        <div className="user-name">
          {
            clientMobile
              ? <><span className="welcome">{welcomeBackText} {profileName}</span></>
              : <><span className="welcome">{welcomeBackText}, </span>
                {profileName}</>
          }
        </div>
        <Avatar size="small" src={profileAvatar}>{profileAvatar ? null : profileInitials}</Avatar>
      </div>
    </Dropdown>
  )
}
export default QUserMenu
