import React, { ReactElement } from 'react'
import { Popover } from 'antd'

import './styles.scss'

export interface Props {
  match: number | string
  description: string
  matchDetails: string[]
  className?: string
  children?: ReactElement | null
}

const QMatchPopover = ({
  match,
  description,
  matchDetails,
  children,
  className = '',
  ...props
}: Props) => {
  const contentMedia = (
    <div>
      <div className="h6 match-popover__title">{match}% Match</div>
      <div className="mt--50 p p--sm tc--light">{description}</div>
      <ul className="mt--125 p p--sm mb-0 fw-500 match-popover__list">
        {matchDetails.map((list: string) => (
          <li key={list}>{list}</li>
        ))}
      </ul>
    </div>
  )

  return (
    <Popover
      overlayClassName={`qu-match-popover ${className}`}
      content={contentMedia}
      placement="right"
      trigger="hover"
      getPopupContainer={(node: any) => node.parentNode}
      {...props}
    >
      {children}
    </Popover>
  )
}
export default QMatchPopover
