import React from 'react'
import { Row, Col, Form, Select, Popover, Radio } from 'antd'

import QInput from '../Input/QInput'
import QCheckbox from '../Checkbox/QCheckbox'
import QSelect from '../Select/QSelect'
import QDatePicker from '../DatePicker/QDatePicker'
import QFileUploader from '../FileUploader/QFileUploader'
import QTextArea from '../TextArea/QTextArea'
import QSummaryCart from './QSummaryCart'
import QSelectItem from '../SelectCard/QSelectItem'
import QButton from '../Buttons/QButton'
import QRadio from '../Radio/QRadio'

import { IconClose, IconList } from '../../components/Icons/IconsCommon'
import { IconMediaBrandStore } from '../../components/Icons/IconsMediaTypes'

import './styles.scss'

export interface Props {
  className?: string
}

const fileList = [
  {
    uid: '-1',
    name: 'VeryNiceFileName.png',
    status: 'done',
    url:
      'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    thumbUrl:
      'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  },
  {
    uid: '-2',
    name: 'VeryBadFileName.png',
    status: 'error'
  }
]

const uploadProps = {
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  listType: 'picture',
  defaultFileList: [...fileList],
  showUploadList: {
    removeIcon: <span>Remove</span>
  }
}

const file = {
  uid: '-1',
  name: 'VeryNiceFileName.png',
  status: 'loading',
  url:
    'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  thumbUrl:
    'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
}

const singleProps = {
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  listType: 'picture-card',
  showUploadList: false,
  onRemove: () => console.log('removed')
}

const selectOptions = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Running',
    value: 'running'
  },
  {
    label: 'Approved',
    value: 'approved'
  },
  {
    label: 'Rejected',
    value: 'rejected'
  },
  {
    label: 'Completed',
    value: 'completed'
  }
]

const content = (
  <div className="distributing-popover">
    <div className="distributing-popover__item">
      <Row gutter={16} align="middle">
        <Col span={10}>
          <img
            src="http://placehold.it/244x164"
            width="122"
            height="82"
            alt=""
            className="distributing-popover__image"
          />
        </Col>
        <Col span={14}>
          <div className="p distributing-popover__heading">Sample</div>
          <p className="p p--sm mb-0 tc--light distributing-popover__description">
            A free product sample that's included for your target customer.
          </p>
        </Col>
      </Row>
    </div>
    <div className="distributing-popover__item">
      <Row gutter={16} align="middle">
        <Col span={10}>
          <img
            src="http://placehold.it/244x164"
            width="122"
            height="82"
            alt=""
            className="distributing-popover__image"
          />
        </Col>
        <Col span={14}>
          <div className="p distributing-popover__heading">Insert</div>
          <p className="p p--sm mb-0 tc--light distributing-popover__description">
            A printed insert to build awareness for your target audience.
          </p>
        </Col>
      </Row>
    </div>
  </div>
)

const contentMedia = (
  <div>
    <div className="banner-popover__image">
      <img src="http://placehold.it/584x340" width="292" height="170" alt="" />
    </div>
    <div className="mt--100 p fw-500">Home Page Banners</div>
    <div className="p p--sm tc--light">
      The largest and most prominent piece of real estate on a website.
    </div>
  </div>
)

const QFormCard = ({ className = '', ...props }: Props) => {
  return (
    <div className={`form form-brief form--horizontal ${className} mb-40`}>
      <div className="form__head">
        <div className="form__number">
          1<span>. Product Brief</span>
        </div>
        <h3>Tell us about your product</h3>
        <Popover
          overlayClassName="qu-summary-popover"
          content={<QSummaryCart />}
          placement="bottomRight"
          trigger="click"
          arrowPointAtCenter
        >
          <div className="qu-summary-button">
            <span className="list-icon">
              <IconList />
            </span>
            <span className="close-icon">
              <IconClose />
            </span>
          </div>
        </Popover>
      </div>
      <div className="form__cont">
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="productName" className="form-label mb-25">
              Product name
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Form.Item
              className="mb-0"
              name="productName"
              help="Enter the name of your product"
            >
              <QInput placeholder="Form Input with Label" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="distributeQuantity" className="form-label">
              How many you want to distribute?
            </label>
          </Col>
          <Col xs={24} sm={24} md={7}>
            <Form.Item className="mb-0" name="distributeQuantity">
              <QInput type="number" placeholder="Enter quantity" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="distributeQuantity" className="form-label">
              What are you distributing?
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Popover
              overlayClassName="qu-distributing-popover"
              content={content}
              placement="rightTop"
              getPopupContainer={(node: any) => node.parentNode}
            >
              <Row gutter={16} align="middle">
                <Col>
                  <Form.Item className="mb-0" name="Sample">
                    <QCheckbox className="test-class">Sample</QCheckbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="mb-0" name="Insert">
                    <QCheckbox className="test-class">Insert</QCheckbox>
                  </Form.Item>
                </Col>
              </Row>
            </Popover>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="productType" className="form-label">
              What is the product type?
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Form.Item className="mb-0" name="productType">
              <QSelect
                showSearch
                size="large"
                placeholder="Select type of the product"
                optionFilterProp="children"
              >
                <Select.Option value="jack">Jack</Select.Option>
                <Select.Option value="lucy">Lucy</Select.Option>
                <Select.Option value="tom">Tom</Select.Option>
              </QSelect>
            </Form.Item>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="startDate" className="form-label mb-25">
              What is your desired start date?
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Form.Item
              validateStatus="error"
              className="mb-0"
              name="startDate"
              help="This is the date which you would like to start this campaign"
            >
              <QDatePicker
                className="full-width"
                size="large"
                placeholder="Select Desired Starting Date"
              />
            </Form.Item>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="productImage" className="form-label mt-35">
              What is your desired start date?
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Form.Item className="mb-0" name="productImage">
              {/* @ts-ignore */}
              <QFileUploader {...uploadProps} />
            </Form.Item>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10}>
            <div className="qu-brand-label">
              <div className="qu-brand-logo">
                <img
                  src="https://www.freeiconspng.com/uploads/pocket-mortys-cheats-icon-18.jpeg"
                  alt="logo"
                />
              </div>
              <div className="qu-brand-info">
                <h4 className="qu-brand-name">Some Company Name</h4>
                <p className="qu-brand-subtitle text-truncate">
                  www.somecompanyname.com
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <p className="form-brief-subtitle mb-15">
              Home Page Banner - Display Ad
            </p>
            <Form.Item>
              {/* @ts-ignore */}
              <QFileUploader mage={file} {...singleProps} />
            </Form.Item>
            <Form.Item className="mb-0">
              <QInput placeholder="enter target URL" size="large" />
            </Form.Item>
            <hr className="form__hr" />
            <p className="form-brief-subtitle mb-15">Monthly Promo - Email</p>
            <Form.Item>
              {/* @ts-ignore */}
              <QFileUploader mage={file} {...singleProps} />
            </Form.Item>
            <Form.Item className="mb-0">
              <QInput placeholder="enter target URL" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="addInstructions" className="form-label">
              Do you have additional instructions?
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Form.Item className="mb-0" name="productName">
              <QTextArea
                rows={3}
                cols={3}
                placeholder="Let us know if you have additional instructions for this brief."
                name="addInstructions"
                id="addInstructions"
                className="form-textarea"
              />
            </Form.Item>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="ageGroups" className="form-label">
              Age Groups?
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Row gutter={16}>
              <Col xs={8}>
                <QSelectItem
                  className="mb-16"
                  label="Children"
                  sublabel="6yrs - 12yrs"
                />
              </Col>
              <Col xs={8}>
                <QSelectItem
                  className="mb-16"
                  label="Children"
                  sublabel="6yrs - 12yrs"
                />
              </Col>
              <Col xs={8}>
                <QSelectItem
                  className="mb-16"
                  label="Children"
                  sublabel="6yrs - 12yrs"
                />
              </Col>
              <Col xs={8}>
                <QSelectItem
                  className="mb-16"
                  label="Children"
                  sublabel="6yrs - 12yrs"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="segments" className="form-label">
              Segments
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Form.Item className="mb-0" name="productName">
              <QSelect
                mode="multiple"
                size="large"
                showArrow
                placeholder="Filter by status"
                options={selectOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="sampleLiquid" className="form-label">
              Sample is liquid
            </label>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Form.Item className="mb-0" name="sampleLiquid">
              <Radio.Group className="qu-radio-group" defaultValue="a">
                <QRadio value="a">Yes</QRadio>
                <QRadio value="b">No</QRadio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <hr className="form__hr" />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10}>
            <label htmlFor="sampleLiquid" className="form-label mb-10">
              Media/Ad Type(s)
            </label>
            <p className="p--xs tc--light">
              Choose the media or ad types where you believe your target
              audience is most likely to respond. For more guidance on what
              media/ad types are right for your product, read our guide on
              effective media selection.
            </p>
            <Form.Item className="mb-0" name="selectAll">
              <QCheckbox className="test-class">Select all</QCheckbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Row gutter={16}>
              <Col xs={8}>
                <QSelectItem
                  className="media-card mb-16"
                  cardImage={<IconMediaBrandStore />}
                  label={
                    <span>
                      Brand Store
                      <p className="p--xs mb-5">(Brand Bundle)</p>
                    </span>
                  }
                />
              </Col>
              <Col xs={8}>
                <QSelectItem
                  className="media-card"
                  cardImage={<IconMediaBrandStore />}
                  label="Store Positioning"
                  checked
                />
              </Col>
              <Col xs={8}>
                <Popover
                  overlayClassName="qu-banner-popover"
                  content={contentMedia}
                  placement="right"
                  getPopupContainer={(node: any) => node.parentNode}
                >
                  <div>
                    <QSelectItem
                      className="media-card"
                      cardImage={<IconMediaBrandStore />}
                      label="Home Page Banners"
                    />
                  </div>
                </Popover>
              </Col>
              <Col xs={8}>
                <QSelectItem
                  className="media-card"
                  cardImage={<IconMediaBrandStore />}
                  label="Online Survey or Campaign"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="form-brief-footer">
          <QButton className="qu-button-outline mt-30 full-width">
            Save & Finish Later
          </QButton>
        </div>
      </div>
    </div>
  )
}
export default QFormCard
