import React from 'react'

import './styles.scss'

export interface Props {
  className?: string
  data?: any
  onClickRequests?: (e: any, name?: string) => void
  onClickCampaigns?: (e: any, name?: string) => void
}

const QLifetimeEarnings = ({
  className = '',
  data,
  onClickRequests,
  onClickCampaigns
}: Props) => {
  return (
    <ul className={`qu-earnings ${className}`}>
      <li className="qu-earnings-item" onClick={onClickRequests}>
        <div className="qu-earnings-value active">{data.requests.value}</div>
        <div className="qu-earnings-label">
          {data.requests.quantity} Open Requests <span>&#10142;</span>
        </div>
      </li>
      <li className="qu-earnings-item" onClick={onClickCampaigns}>
        <div className="qu-earnings-value">{data.campaigns.value}</div>
        <div className="qu-earnings-label">
          {data.campaigns.quantity} Active Campaigns <span>&#10142;</span>
        </div>
      </li>
      <li className="qu-earnings-item">
        <div className="qu-earnings-value">{data.total}</div>
        <div className="qu-earnings-label">Lifetime Earnings</div>
      </li>
    </ul>
  )
}
export default QLifetimeEarnings
