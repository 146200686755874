import React from 'react'
import './styles.scss'

export interface Props {
  description?: any
  input?: string | React.ReactNode
}

const PublicProfileField = (
  {
    description,
    input
  }: Props) => (
    <div className="PPField">
      <div className="PPField__description">
        {
          description.title &&
          <h3 className="PPField__description-title">{description.title}</h3>
        }
        {
          description.subTitle &&
          <p className="PPField__description-subTitle">{description.subTitle}</p>
        }
      </div>
      <div className="PPField__input">
        {input}
      </div>
    </div>
  )

export default PublicProfileField