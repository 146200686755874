import React from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text
} from 'recharts'

import { abbreviateNumber, getLocaleCost } from '../../helpers/number'
import './styles.scss'
import { chartColors } from '../../constants/colors'
import { CustomizedLabel } from './CustomizedLabel'

const CustomTooltip = ({ active, payload, isImpressions }) => {
  if (active) {
    return (
      <div className="qu-chart-tooltip">
        <ul className="tooltip-list">
          {payload.map((item, i) => {
            return (
              <li key={i} className="tooltip-list-item">
                <span className="name"><strong>{item.payload.name}:</strong> {getLocaleCost(item.value)} {isImpressions && 'impressions'}</span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return null
}

export interface Props {
  className?: string
  data?: any
  colors?: object
  xAngle?: number
  yAngle?: number
  isImpressions?: boolean
}

const QBarChartSimple = ({ className = '', data, colors, xAngle = 0, yAngle = 0, isImpressions = false }: Props) => {
  const CustomizedXAxisTick = ({ x, y, payload }) => {
    const value = !isNaN(payload.value)
      ? abbreviateNumber(payload.value)
      : payload.value
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          angle={xAngle}
          fill="#6E8590"
          className="qu-chart-axis-tick"
          width={100}
          x={0}
          y={0}
          verticalAnchor="start"
          textAnchor="end"
        >
          {value.length > 10 ? value.substring(0, 10) + '...' : value}
        </Text>
      </g>
    )
  }

  const CustomizedYAxisTick = ({ x, y, payload }) => {
    const value = !isNaN(payload.value)
      ? abbreviateNumber(payload.value)
      : payload.value
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          angle={yAngle}
          fill="#6E8590"
          className="qu-chart-axis-tick"
          width={100}
          x={0}
          y={0}
          verticalAnchor="middle"
          textAnchor="middle"
        >
          {value}
        </Text>
      </g>
    )
  }

  return (
    <div className={`qu-chart qu-chart-bar ${className}`}>
      <ResponsiveContainer>
        <BarChart
          maxBarSize={14}
          data={data}
          margin={{ top: 15, right: 0, bottom: 0, left: -10 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={false}
            interval={0}
            height={100}
            // @ts-ignore
            tick={<CustomizedXAxisTick />}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            // @ts-ignore
            tick={<CustomizedYAxisTick />}
          />
          <Tooltip
            // @ts-ignore
            content={<CustomTooltip isImpressions={isImpressions} />}
            cursor={{ fill: 'rgb(243 244 246 / 30%)' }}
          />
          {/* @ts-ignore */}
          <Bar dataKey="value" label={<CustomizedLabel />}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors ? colors[index] : chartColors[index]}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
export default QBarChartSimple
