import React from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'

import './styles.scss'

const QButton = ({
  children = 'Button',
  className = '',
  ...props
}: ButtonProps) => {
  return (
    <Button className={`qu-button ${className}`} {...props}>
      {children}
    </Button>
  )
}
export default QButton
