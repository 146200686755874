import React from 'react'

import { Table } from 'antd'
import './styles.scss'

export interface Props {
  columns: any
  dataSource: any
  scroll?: any
  className?: string
  loading?: boolean
}

const QTable = ({
  columns,
  dataSource,
  scroll,
  className = 'q-table',
  loading = false,
  ...props
}: Props) => (
  <Table
    columns={columns}
    dataSource={dataSource}
    pagination={false}
    tableLayout="fixed"
    scroll={scroll}
    className={className}
    loading={loading}
    {...props}
  />
)

export default QTable
