import React, { ReactElement } from 'react'

import './styles.scss'

export interface Props {
  className?: string
  childrenCenter: ReactElement | null
  childrenRight?: ReactElement | null
}

const QBriefFooter = ({
  className = '',
  childrenCenter,
  childrenRight,
  ...props
}: Props) => {
  return (
    <footer className={`qu-brief-footer ${className}`} {...props}>
      <div className="footer-left" />
      <div className="footer-center">{childrenCenter}</div>
      <div className="footer-right">{childrenRight}</div>
    </footer>
  )
}
export default QBriefFooter
