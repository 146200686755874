import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text
} from 'recharts'

import './styles.scss'
import { CustomizedLabel } from './CustomizedLabel'
import { abbreviateNumber, getLocaleCost } from '../../helpers/number'

const dataChart = [
  { name: 'Dec 2019', Media: 4000, Inserts: 2400, Samples: 1200, amt: 2400 },
  { name: 'Jan 2020', Media: 3000, Inserts: 1398, Samples: 900, amt: 2210 },
  { name: 'Feb 2020', Media: 2000, Inserts: 9800, Samples: 3000, amt: 2290 },
  { name: 'Mar 2020', Media: 2780, Inserts: 3908, Samples: 2000, amt: 2000 },
  { name: 'Apr 2020', Media: 1890, Inserts: 4800, Samples: 1500, amt: 2181 },
  { name: 'May 2020', Media: 2390, Inserts: 3800, Samples: 2000, amt: 2500 }
]

// custom labels
const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#6E8590">
        {payload.value}
      </text>
    </g>
  )
}

const CustomTooltip = ({ active, payload }) => {
  const sum = payload
    .map((item) => item.value)
    .reduce((prev, curr) => prev + curr, 0)
  if (active) {
    return (
      <div className="qu-chart-tooltip">
        <ul className="tooltip-list">
          {!!payload.length && <li className="tooltip-list-item x-axis-name">{payload[0].payload.name}</li>}
          {payload.map((item, i) => {
            return (
              <li
                key={i}
                className={`tooltip-list-item dots ${item.name.toLowerCase()}`}
              >
                <span className="name">{item.name}</span>
                <span className="value">{getLocaleCost(item.value)}</span>
              </li>
            )
          })}
          <li className="tooltip-list-item total">
            <span className="name">Total</span>
            <span className="value">{getLocaleCost(sum)}</span>
          </li>
        </ul>
      </div>
    )
  }

  return null
}

const CustomLabel = ({ x, y, width, value }) => {
  return (
    <g transform={`translate(${x + width / 2},${y})`}>
      <text
        x={0}
        y={0}
        dy={-4}
        style={{ fontSize: '13px', fontWeight: 600, fill: '#1E252B' }}
        textAnchor="middle"
      >
        {value > 0 ? value : null}
      </text>
    </g>
  )
}

export interface Props {
  className?: string
  data?: any
  xAngle?: number
  yAngle?: number
}

const QBarChart = ({ className = '', data, xAngle = 0, yAngle = 0 }: Props) => {
  const CustomizedXAxisTick = ({ x, y, payload }) => {
    const value = !isNaN(payload.value)
      ? abbreviateNumber(payload.value)
      : payload.value
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          angle={xAngle}
          fill="#6E8590"
          className="qu-chart-axis-tick"
          width={100}
          x={0}
          y={0}
          verticalAnchor="start"
          textAnchor="end"
        >
          {value.length > 10 ? value.substring(0, 10) + '...' : value}
        </Text>
      </g>
    )
  }

  return (
    <div className={`qu-chart qu-chart-bar ${className}`}>
      <ResponsiveContainer>
        <BarChart
          maxBarSize={14}
          data={data}
          margin={{ top: 15, right: 0, bottom: 0, left: -10 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={false}
            interval={0}
            height={60}
            // @ts-ignore
            tick={<CustomizedXAxisTick />}
          />
          <YAxis angle={yAngle} axisLine={false} tickLine={false} tick={{ fill: '#6E8590' }} />
          <Tooltip
            // @ts-ignore
            content={<CustomTooltip />}
            cursor={{ fill: 'rgb(243 244 246 / 30%)' }}
          />
          <Legend iconType="circle" />
          <Bar dataKey="Media" stackId="a" fill="#D9C4E2" />
          <Bar dataKey="Inserts" stackId="a" fill="#1379AC" />
          <Bar
            dataKey="Samples"
            stackId="a"
            fill="#9EDFAD"
            // @ts-ignore
            label={<CustomizedLabel />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
export default QBarChart
