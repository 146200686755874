import React, { useCallback, useEffect, useState } from 'react'
import { Form, Modal, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import QButton from '../Buttons/QButton'
import QInput from '../Input/QInput'
import { IconClose } from '../Icons/IconsCommon'
import QSelect from '../Select/QSelect'
import './styles.scss'

export interface Props {
  departmentFormValues: any
  SubmitDepartment: any

  teamMemberFormValues: any
  SubmitTeamMember: any

  expandAll: any
  collapseAll: any
}

const QTreeControls = ({
  departmentFormValues,
  SubmitDepartment,

  teamMemberFormValues,
  SubmitTeamMember,

  expandAll,
  collapseAll
}: Props) => {

  /* Department Starts */
  const [departmentForm] = Form.useForm()
  const [departmentModalVisible, setDepartmentModalVisible] = useState<boolean>(false)
  const [positionOptions, setPositionOptions] = useState<[]>()
  const [departmentOptions, setDepartmentOptions] = useState<[]>()
  const [newDepartment, setNewDepartment] = useState<boolean>(false)
  const [departmentButtonDisable, setDepartmentButtonDisable] = useState<boolean>(false)

  /* when ever new departmentFormValues props comes then the below funtion will run */
  useEffect(() => {
    // set is new department or not based on id get from the props
    if (departmentFormValues.id && departmentFormValues.id > 0)
      setNewDepartment(false);
    else
      setNewDepartment(true);

    // set is submit department button disable or not based on department data get from the props
    setDepartmentButtonDisable(departmentFormValues.isSubmitButtonDisable);

    // Is Modal visible or not?
    setDepartmentModalVisible(departmentFormValues.isDepartmentModalOpen);

    // set position dropdown values and department dropdown values as per the is account owner field
    if (departmentFormValues.isOwner || departmentFormValues.departments.length == 2) {
      setPositionOptions(departmentFormValues.positions.slice(0, 1))
      setDepartmentOptions(departmentFormValues.departments.slice(0, 1))
    } else {
      let departments: any = [];
      departmentFormValues.departments.map(ele => {
        if (!ele.isOwner)
          departments.push({ label: ele.label, value: ele.value })
      })
      setPositionOptions(departmentFormValues.positions)
      setDepartmentOptions(departments)
    }

    // set the department form values for new or existing
    departmentForm.setFieldsValue({
      departmentName: departmentFormValues.departmentName,
      locateOptions: {
        position: departmentFormValues.locateOptions.position,
        department: departmentFormValues.locateOptions.department
      }
    })
  }, [departmentFormValues])

  /* when department modal open then the below funtion will run */
  const onOpenDepartmentModal = () => {
    // Is Modal visible or not?
    setDepartmentModalVisible(true);

    // set the new department false
    setNewDepartment(true);

    // set is submit department button disable false
    setDepartmentButtonDisable(false);

    // set position dropdown values and department dropdown values as per the total departments
    if (departmentFormValues.departments.length == 2) {
      setPositionOptions(departmentFormValues.positions.slice(0, 1))
      setDepartmentOptions(departmentFormValues.departments.slice(0, 1))
    } else {
      let departments: any = [];
      departmentFormValues.departments.map(ele => {
        if (!ele.isOwner)
          departments.push({ label: ele.label, value: ele.value })
      })
      setPositionOptions(departmentFormValues.positions)
      setDepartmentOptions(departments)
    }

    // set the department form values for new or existing
    departmentForm.setFieldsValue({
      departmentName: '',
      locateOptions: {
        position: null,
        department: null
      }
    })
  }

  /* when department modal close without saving anything then the below funtion will run */
  const onCloseDepartmentModal = () => {
    setDepartmentModalVisible(false);
  }

  /* when department submit button hits then the below funtion will run */
  const onDepartmentFinish = () => {
    const formData = departmentForm.getFieldsValue()
    departmentForm.validateFields().then(() => {
      setDepartmentButtonDisable(true);
      if (newDepartment)
        SubmitDepartment({ formData })
      else
        SubmitDepartment({ formData, id: departmentFormValues.id })
    })
  }

  /* when department submit button hits and vaidations failed then the below funtion will run */
  const onDepartmentFinishFailed = (errorInfo) => { };
  /* Department Ends */


  /* Team Member Starts */
  const [teamMemberForm] = Form.useForm()
  const [teamMemberModalVisible, setTeamMemberModalVisible] = useState<boolean>(false)
  const [departmentsOptions, setDepartmentsOptions] = useState<[]>()
  const [permissionsOptions, setPermissionsOptions] = useState<[]>()
  const [newTeamMember, setNewTeamMember] = useState<boolean>(false)
  const [teamMemberButtonDisable, setTeamMemberButtonDisable] = useState<boolean>(false)
  const [addNewTeamMemberButtonDisable, setAddNewTeamMemberButtonDisable] = useState<boolean>(false)

  /* when ever new teamMemberFormValues props comes then the below funtion will run */
  useEffect(() => {
    // set is add team member button disable or not based on props
    setAddNewTeamMemberButtonDisable(teamMemberFormValues.isAddMemberbuttonDisable);

    // set is new team member or not based on id get from the props
    if (teamMemberFormValues.id && teamMemberFormValues.id > 0)
      setNewTeamMember(false);
    else
      setNewTeamMember(true);

    // set is submit team member button disable or not based on team member data get from the props
    setTeamMemberButtonDisable(teamMemberFormValues.isSubmitButtonDisable);

    // Is Modal visible or not?
    setTeamMemberModalVisible(teamMemberFormValues.isMemberModalOpen);

    // set departments dropdown values as per the "is account owner" field
    if (teamMemberFormValues.isOwner) {
      let departments: any = [];
      teamMemberFormValues.departments.map(ele => {
        departments.push({ label: ele.label, value: ele.value })
      })
      setDepartmentsOptions(departments)
    } else {
      let departments: any = [];
      teamMemberFormValues.departments.map(ele => {
        if (!ele.isOwner)
          departments.push({ label: ele.label, value: ele.value })
      })
      setDepartmentsOptions(departments)
    }

    // set permissions dropdown values
    setPermissionsOptions(teamMemberFormValues.permissions)

    // set the team member form values for new or existing
    teamMemberForm.setFieldsValue({
      firstName: teamMemberFormValues.firstName,
      lastName: teamMemberFormValues.lastName,
      email: teamMemberFormValues.email,
      partnerDepartmentId: teamMemberFormValues.partnerDepartmentId,
      partnerPermissionLevel: teamMemberFormValues.partnerPermissionLevel
    })
  }, [teamMemberFormValues])

  /* when team member modal open then the below funtion will run */
  const onOpenTeamMemberModal = () => {
    // Is Modal visible or not?
    setTeamMemberModalVisible(true);

    // set the new team member false
    setNewTeamMember(true);

    // set is submit team member button disable false
    setTeamMemberButtonDisable(false);

    // set position dropdown values and department dropdown values as per the total departments
    if (departmentFormValues.departments.length == 2) {
      setPositionOptions(departmentFormValues.positions.slice(0, 1))
      setDepartmentOptions(departmentFormValues.departments.slice(0, 1))
    } else {
      let departments: any = [];
      departmentFormValues.departments.map(ele => {
        if (!ele.isOwner)
          departments.push({ label: ele.label, value: ele.value })
      })
      setPositionOptions(departmentFormValues.positions)
      setDepartmentOptions(departments)
    }

    // set departments dropdown values excpet owner record
    let departments: any = [];
    teamMemberFormValues.departments.map(ele => {
      if (!ele.isOwner)
        departments.push({ label: ele.label, value: ele.value })
    })
    setDepartmentsOptions(departments)

    // set permissions dropdown values
    setPermissionsOptions(teamMemberFormValues.permissions)

    // set the team member form values for new or existing
    teamMemberForm.setFieldsValue({
      firstName: "",
      lastName: "",
      email: "",
      partnerDepartmentId: "",
      partnerPermissionLevel: ""
    })
  }

  /* when team member modal close without saving anything then the below funtion will run */
  const onCloseTeamMemberModal = () => {
    setTeamMemberModalVisible(false);
  }

  /* when team member submit button hits then the below funtion will run */
  const onTeamMemberFinish = () => {
    const formData = teamMemberForm.getFieldsValue()
    teamMemberForm.validateFields().then(() => {
      setTeamMemberButtonDisable(true);
      if (newTeamMember)
        SubmitTeamMember({ formData })
      else
        SubmitTeamMember({ formData, id: teamMemberFormValues.id })
    })
  }

  /* when team member submit button hits and validation failed then the below funtion will run */
  const onTeamMemberFinishFailed = (errorInfo) => { };
  /* Team Member Ends */

  // start expand all
  const submitExpandAll = () => {
    expandAll(true);
  }
  // end expand all

  // start collapse all
  const submitCollapseAll = () => {
    collapseAll(true);
  }
  // end collapse all

  return (
    <div className="qu-tree-controls">
      <QButton type="primary" style={{ width: 'unset' }} onClick={onOpenDepartmentModal}>
        Add Department <PlusOutlined className="font-size-18" />
      </QButton>
      <QButton disabled={addNewTeamMemberButtonDisable} className="qu-button-soft" style={{ width: 'unset' }} onClick={onOpenTeamMemberModal}>
        Add Member <PlusOutlined className="font-size-18" />
      </QButton>
      <div className="expand-group mt-10">
        <button onClick={submitExpandAll}>Expand All</button>
        <button onClick={submitCollapseAll}>Collapse All</button>
      </div>

      <Modal
        wrapClassName="qu-modal"
        closeIcon={<IconClose />}
        visible={departmentModalVisible}
        onCancel={onCloseDepartmentModal}
        width={400}
        title={newDepartment ? <h3>Add New Department</h3> : <h3>Update Department</h3>}
        footer={null}
      >
        <div className="">
          <div className="grid-row">
            <div className="grid-col">
              <Form layout="vertical" form={departmentForm} onFinish={onDepartmentFinish} onFinishFailed={onDepartmentFinishFailed}>
                <Form.Item label="Department Name" name="departmentName" rules={[{ required: true, message: 'Please enter department name', }]}>
                  <QInput size="large" />
                </Form.Item>

                <Form.Item label="Locate">
                  <Input.Group className="qu-input-group" size="large" compact>
                    <Form.Item
                      name={['locateOptions', 'position']}
                      noStyle
                    >
                      <QSelect
                        style={{ width: '50%' }}
                        showArrow
                        options={positionOptions}
                        size="large"
                      />
                    </Form.Item>

                    <Form.Item
                      name={['locateOptions', 'department']}
                      noStyle
                    >
                      <QSelect
                        style={{ width: '50%' }}
                        showArrow
                        options={departmentOptions}
                        size="large"
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <div className="grid-row grid-row--jce">
                  <div className="grid-col grid-col--auto">
                    <QButton type="primary" block htmlType="submit" disabled={departmentButtonDisable}>
                      {newDepartment ? 'Create' : 'Update'}
                    </QButton>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        wrapClassName="qu-modal"
        closeIcon={<IconClose />}
        visible={teamMemberModalVisible}
        onCancel={onCloseTeamMemberModal}
        width={400}
        title={newTeamMember ? <h3>Add Team Member</h3> : <h3>Update Team Member</h3>}
        footer={null}
      >
        <div className="">
          <div className="grid-row">
            <div className="grid-col">
              <Form layout="vertical" form={teamMemberForm} onFinish={onTeamMemberFinish} onFinishFailed={onTeamMemberFinishFailed}>
                <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Please enter first name', }]} style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
                  <QInput size="large" />
                </Form.Item>

                <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Please enter last name', }]} style={{ display: 'inline-block', width: 'calc(50%)', margin: '0 4px' }}>
                  <QInput size="large" />
                </Form.Item>

                <Form.Item label="Email Address" name="email" rules={[{ required: true, message: 'Please enter email address' }, {
                  pattern: /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/, message: 'Please enter a valid email address'
                }]}>
                  <QInput size="large" disabled={(!newTeamMember && teamMemberFormValues.id) ? true : false} />
                </Form.Item>

                <Form.Item label="Department" name="partnerDepartmentId" rules={[{ required: true, message: 'Please select department' }]}>
                  <QSelect
                    disabled={(!newTeamMember && teamMemberFormValues.id && teamMemberFormValues.isOwner) ? true : false}
                    showArrow
                    options={departmentsOptions}
                    size="large"
                  />
                </Form.Item>

                <Form.Item label="Permission" name="partnerPermissionLevel" rules={[{ required: true, message: 'Please select permission' }]}>
                  <QSelect
                    showArrow
                    disabled={(!newTeamMember && teamMemberFormValues.id && teamMemberFormValues.isOwner) ? true : false}
                    options={permissionsOptions}
                    size="large"
                  />
                </Form.Item>

                <div className="grid-row grid-row--jce">
                  <div className="grid-col grid-col--auto">
                    <QButton type="primary" block htmlType="submit" disabled={teamMemberButtonDisable}>
                      {newTeamMember ? 'Create' : 'Update'}
                    </QButton>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default QTreeControls
