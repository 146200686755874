import { Checkbox } from 'antd'
import React from 'react'
import './styles.scss'

export interface Props {
  isSelected?: boolean
  headerImg?: string
  name?: string
  description?: string
  onLearnMoreClick?: () => void
  handleSelect?: () => void
}

const QAvailableServiceCard = ({
  isSelected = false,
  headerImg,
  name,
  description,
  onLearnMoreClick,
  handleSelect,
}: Props) => {
  return (
    <div className={`AvailableServiceCard ${isSelected ? 'selected' : ''}`}>
      <div className="AvailableServiceCard__thumb">
        <Checkbox className={`qu-checkbox`} checked={isSelected} onChange={handleSelect}></Checkbox>
        <img src={headerImg} alt="thumbnail" />
      </div>

      <div className="AvailableServiceCard__header">
        <div className="AvailableServiceCard__header-info">
          {name && <span className="AvailableServiceCard__header-name">{name}</span>}
          {description && <div className="AvailableServiceCard__header-description">{description}</div>}
        </div>
      </div>

      <div className="AvailableServiceCard__footer">
        <a onClick={onLearnMoreClick} className="learn-more-link">Learn More</a>
      </div>
    </div>
  )
}

export default QAvailableServiceCard
