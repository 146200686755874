import React from 'react'

import { Pagination } from 'antd'
import './styles.scss'

const QPagination = ({ className = '', ...props }) => {
  return <Pagination className={`qu-pagination ${className}`} {...props} />
}

export default QPagination
