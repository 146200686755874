import React from 'react'
import { Radio } from 'antd'

import './styles.scss'

const QRadio = ({ children = 'Radio', className = '', isNewBadge = false, ...props }) => {
  return (
    <Radio className={`qu-radio ${className}`} {...props}>
      {children} {isNewBadge ? <>&nbsp;<span className="new_badge">&nbsp;NEW&nbsp;</span></> : ''}
    </Radio>
  )
}
export default QRadio