import React from 'react'

import './styles.scss'

const ProductTypeCard = (props) => {
  const { className = '', icon, name } = props

  return (
    <div className={`BS product-types-card ${className}`}>
      {icon ? (
        <div>{icon}</div>
      ) : (
        <div className="product-types-icon-placeholder" />
      )}
      <p>{name}</p>
    </div>
  )
}

export default ProductTypeCard
