import { Col, Row } from 'antd'
import React from 'react'

import './styles.scss'

export interface Props {
  className?: string
  summaryPrice: string
  summaryName: string
  summaryDescription: string
  onRemove?: (e: any, name?: string) => void
  hideRemove?: boolean
  summarySubName?: string
  bundlesDescription?: string
}

const QBriefCartItem = ({
  className = '',
  summaryPrice = '$0',
  summaryName = 'Product Name',
  summaryDescription = 'description',
  onRemove = () => null,
  hideRemove = false,
  summarySubName = '',
  bundlesDescription = ''
}: Props) => {
  return (
    <div className={`selection-summary__item ${className}`}>
      <div className="p fw-700 selection-summary__price">{summaryPrice}</div>
      <div className="p fw-500 selection-summary__name">{summaryName}</div>
      {summarySubName && <Row className="p p--xs tc--light selection-summary__description">
        <Col>
          {summarySubName}
        </Col>
      </Row>}
      {bundlesDescription && <Row className="p p--xs tc--light selection-summary__description">
        <Col>
          {bundlesDescription}
        </Col>
      </Row>}
      <Row className="p p--xs tc--light selection-summary__description">
        {summaryDescription && <Col>
          {summaryDescription}
        </Col>}
        <Col className={`${!summaryDescription && 'qu-button-col'}`}>
          {!hideRemove && (
            <span className="qu-button-link" onClick={onRemove}>
              Remove
            </span>
          )}
        </Col>
      </Row>
    </div>
  )
}
export default QBriefCartItem
