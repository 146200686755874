import React, { ReactElement } from 'react'

import './styles.scss'

export interface Props {
  title: string
  onClickPath?: () => void
  status?: string
}

const lock = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z"
    />
  </svg>
)

const QBriefStep = ({ title, onClickPath, status, ...props }: Props) => {
  return (
    <>
      <span className="steps__divider" />
      <span
        onClick={onClickPath}
        className={`steps__item steps__item--${status}`}
      >
        <span>
          {status === 'disabled' && lock}
          {title}
        </span>
      </span>
    </>
  )
}
export default QBriefStep
