import React from 'react'

import './styles.scss'

export interface Props {
  className?: string
  productImage?: string
  productName: string
  onEdit?: (e: any, name?: string) => void
  isAdditional?: boolean
  disableEdit?: boolean
}

const QBriefCartProduct = ({
  className = '',
  productImage = 'http://placehold.it/64x64',
  productName = 'Product Name',
  onEdit = () => null,
  isAdditional = false,
  disableEdit = false
}: Props) => {
  return (
    <div className={`grid-row grid-row--aic ${isAdditional ? 'grid-row--smg-add' : 'grid-row--smg'} ${className}`}>
      <div className="grid-col grid-col--auto">
        {!isAdditional && <div className="selection-summary__image">
          {productImage && (
            <img
              src={productImage}
              width="32"
              height="32"
              alt="product logo"
              className="selection-summary__logo"
            />
          )}
        </div>}
      </div>
      <div className="grid-col">
        {
          isAdditional
            ? <span className="additionalChargesName">{productName}</span>
            : <h3 className="ml-8">{productName}</h3>
        }
      </div>
      <div className="grid-col grid-col--auto">
        <div className="p p--xs tc--light">
          {!disableEdit && <span className="qu-button-link font-size-14" onClick={onEdit}>
            Edit
          </span>}
        </div>
      </div>
    </div>
  )
}
export default QBriefCartProduct
