import React from 'react'
import { DatePicker } from 'antd'

import './styles.scss'

const QDatePicker = ({ className = '', ...props }) => {
  return <DatePicker className={`qu-datepicker ${className}`} {...props} />
}

export default QDatePicker
