import React from 'react'

import { Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert'

import './styles.scss'

const QAlert = ({
  className = '',
  ...props
}: AlertProps) => (
    <div className={`qu-alert ${className}`}>
      <Alert {...props} />
    </div>
  )

export default QAlert

