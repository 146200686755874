import React, { ReactElement } from 'react'

import './styles.scss'

const iconEmpty = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z"
    ></path>
  </svg>
)

export interface Props {
  label: string | ReactElement
  sublabel?: string
  checked?: boolean
  onChange?: (e: any, name?: string) => void
  className?: string
  cardType?: string
  inputName?: string
  cardImage?: ReactElement | any
}

const QSelectItem = ({
  className = '',
  label = 'Label',
  sublabel,
  cardType = 'checkbox',
  inputName = 'card',
  cardImage = iconEmpty,
  checked = false,
  onChange = () => null,
  ...props
}: Props) => {
  return (
    <div className={`form-card ${className}`}>
      <input
        className="form-card__input"
        checked={checked}
        onChange={onChange}
        type={cardType}
        name={inputName}
        {...props}
      />
      <span className="form-card__image">{cardImage}</span>
      <label className="form-card__label">{label}</label>
      {sublabel && <div className="form-card__sublabel">{sublabel}</div>}
      <span className="form-card__icon" />
    </div>
  )
}
export default QSelectItem
