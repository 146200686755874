import React, { ReactElement } from 'react'
import { Popover } from 'antd'

import './styles.scss'
import { IPartnerCardService } from '../ProfileCard/ProfileCard'

const packageIcon = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        x="-2.1%"
        y="-1.4%"
        width="104.2%"
        height="103.9%"
        filterUnits="objectBoundingBox"
        id="b"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0772508741 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="a" x="0" y="0" width="330" height="360" rx="10" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-13 -292)">
        <use fill="#000" filter="url(#b)" href="#a" />
        <rect
          strokeOpacity=".05"
          stroke="#000"
          fill="#FFF"
          x=".5"
          y=".5"
          width="329"
          height="359"
          rx="10"
        />
      </g>
      <path
        d="M19.936 4.426L16.47 1.121A.37.37 0 0016.185 1H4.95a.37.37 0 00-.286.121L1.2 4.426A.782.782 0 001 4.95v14.779c0 .246.164.406.406.406h18.326c.247 0 .407-.164.407-.406V4.91a.762.762 0 00-.203-.485zm-8.962-2.613h5.051l2.688 2.566h-7.74V1.813h0zm-5.864 0h5.051v2.566H2.422L5.11 1.813zm-3.297 8.43h3.5v4.07h-3.5v-4.07h0zm17.51 4.07h-3.501v-4.07h3.5v4.07zm0-4.886h-3.911c-.246 0-.407.164-.407.406v4.887c0 .246.164.407.407.407h3.91v4.195H1.812v-4.195h3.911a.437.437 0 00.406-.407V9.833c0-.246-.164-.406-.406-.406h-3.91V5.192h17.51v4.235z"
        fill="#1E252B"
        fillRule="nonzero"
        opacity=".5"
        stroke="#1E252B"
        strokeWidth=".1"
      />
    </g>
  </svg>
)
const deviceIcon = (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        x="-2.1%"
        y="-1.4%"
        width="104.2%"
        height="103.9%"
        filterUnits="objectBoundingBox"
        id="b"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0772508741 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="a" x="0" y="0" width="330" height="360" rx="10" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-89 -293)">
        <use fill="#000" filter="url(#b)" href="#a" />
        <rect
          strokeOpacity=".05"
          stroke="#000"
          fill="#FFF"
          x=".5"
          y=".5"
          width="329"
          height="359"
          rx="10"
        />
      </g>
      <path
        d="M22.395 0c.9038 0 1.595.6912 1.595 1.595v12.759c0 .9039-.6912 1.595-1.595 1.595h-6.3795v1.5949c0 .319.2126.5316.5316.5316h.5316c.319 0 .5316.2127.5316.5316 0 .319-.2126.5316-.5316.5316H9.636c-.319 0-.5316-.2127-.5316-.5316 0-.319.2126-.5316.5316-.5316h.5316c.319 0 .5316-.2126.5316-.5316v-1.595H9.6358c-.319 0-.5316-.2126-.5316-.5315 0-.319.2126-.5317.5316-.5317H22.395c.319 0 .5316-.2126.5316-.5316V1.595c0-.3189-.2126-.5316-.5316-.5316H4.3195c-.319 0-.5316.2127-.5316.5316v5.3163c0 .319-.2127.5316-.5316.5316-.319 0-.5317-.2126-.5317-.5316V1.595C2.7246.691 3.4157 0 4.3196 0zM6.446 8.5061c.9038 0 1.595.6912 1.595 1.595v7.4428c0 .9037-.6912 1.5949-1.595 1.5949H2.193c-.9038 0-1.595-.6912-1.595-1.595V10.101c0-.9037.6912-1.5949 1.595-1.5949zm8.5061 7.443h-3.1898v1.5948c0 .2126-.0531.3721-.1063.5316h3.4024c-.0531-.1595-.1063-.319-.1063-.5316V15.949zm-8.506-6.3797H2.193c-.319 0-.5316.2126-.5316.5316v7.4429c0 .319.2126.5316.5316.5316h4.253c.319 0 .5317-.2126.5317-.5316V10.101c0-.319-.2127-.5316-.5317-.5316zm-2.1266 6.9112a.5316.5316 0 110 1.0633.5316.5316 0 010-1.0633zm9.0378-3.1898a.5316.5316 0 110 1.0633.5316.5316 0 010-1.0633z"
        fill="#1E252B"
        fillRule="nonzero"
        opacity=".5"
      />
    </g>
  </svg>
)

export interface Props {
  popupServices: IPartnerCardService[]
  onClick?: (e: any, name?: string) => void
  className?: string
  children?: ReactElement | null
  priceBlur?: string
  hideDistributionPrice?: boolean
  hideMediaBuyingPrice?: boolean
}

const QPricePopover = ({
  popupServices,
  children,
  className = '',
  onClick,
  priceBlur,
  hideDistributionPrice,
  hideMediaBuyingPrice,
  ...props
}: Props) => {
  const distribution = popupServices.filter(
    (s: IPartnerCardService) => s.type === 'Distribution'
  )

  const totalCount = popupServices.length

  const popServices = popupServices.filter(
    (s: IPartnerCardService) => s.subtype === 'Display Ad' || s.subtype === 'Direct Communication' || s.subtype === 'Promotion'
  )

  const newPopServices = popServices.length ? popServices.splice(0, 2) : popServices

  const displayAd = newPopServices.filter(
    (s: IPartnerCardService) => s.subtype === 'Display Ad'
  )

  const communications = newPopServices.filter(
    (s: IPartnerCardService) => s.subtype === 'Direct Communication'
  )

  const promotions = newPopServices.filter(
    (s: IPartnerCardService) => s.subtype === 'Promotion'
  )

  const calcMoreNumber = () => {
    return totalCount - distribution.length - newPopServices.length
  }

  const contentMedia = (
    <div>
      <div>
        {
          !!distribution.length &&
          <>
            <div className="grid-row grid-row--smg grid-row--aic prices-popover__heading">
              <div className="grid-col grid-col--auto">
                <div className="prices-popover__icon">{packageIcon}</div>
              </div>
              <div className="grid-col">
                <div className="h6">Distribution</div>
              </div>
            </div>
            <dl className="p--sm prices-popover__list">
              {distribution.map((s: IPartnerCardService) => (
                <React.Fragment key={s.name}>
                  <dt>{s.name}</dt>
                  <dd className={`${priceBlur} ${hideDistributionPrice?"hidden":""}`}>{s.price}</dd>
                </React.Fragment>
              ))}
            </dl>
          </>
        }

        {
          (!!displayAd.length || !!communications.length || !!promotions.length || !!newPopServices.length) &&
          <>
            <div className="grid-row grid-row--smg grid-row--aic prices-popover__heading">
              <div className="grid-col grid-col--auto">
                <div className="prices-popover__icon">{deviceIcon}</div>
              </div>
              <div className="grid-col">
                <div className="h6">Media Buying</div>
              </div>
            </div>

            {
              (displayAd.length || communications.length || promotions.length) && (displayAd.filter(ele => ele.subtype === 'Display Ad').length === 2 || communications.filter(ele => ele.subtype === 'Direct Communication').length === 2 || promotions.filter(ele => ele.subtype === 'Promotion').length === 2)
                ? <>
                  {displayAd.length > 0 && (
                    <React.Fragment>
                      <div className="p p--sm fw-700 prices-popover__caption">
                        Display Ad
                      </div>
                      <dl className="p--sm prices-popover__list">
                        {displayAd.map((s: IPartnerCardService) => (
                          <React.Fragment key={s.name}>
                            <dt>{s.name}</dt>
                            <dd className={`${priceBlur} ${hideMediaBuyingPrice?"hidden":""}`}>{s.price}</dd>
                          </React.Fragment>
                        ))}
                      </dl>
                    </React.Fragment>
                  )}

                  {communications.length > promotions.length ? (
                    <React.Fragment>
                      <div className="p p--sm fw-700 prices-popover__caption">
                        Direct Communication
                      </div>
                      <dl className="p--sm prices-popover__list">
                        {communications.map((s: IPartnerCardService) => (
                          <React.Fragment key={s.name}>
                            <dt>{s.name}</dt>
                            <dd className={`${priceBlur} ${hideMediaBuyingPrice?"hidden":""}`}>{s.price}</dd>
                          </React.Fragment>
                        ))}
                      </dl>
                    </React.Fragment>
                  ) : (
                    promotions.length > 0 && <React.Fragment>
                      <div className="p p--sm fw-700 prices-popover__caption">
                        Promotion
                      </div>
                      <dl className="p--sm prices-popover__list">
                        {promotions.map((s: IPartnerCardService) => (
                          <React.Fragment key={s.name}>
                            <dt>{s.name}</dt>
                            <dd className={`${priceBlur} ${hideMediaBuyingPrice?"hidden":""}`}>{s.price}</dd>
                          </React.Fragment>
                        ))}
                      </dl>
                    </React.Fragment>
                  )}
                </>
                : newPopServices.length > 0 && newPopServices.map((ele, index) =>
                  <React.Fragment key={index}>
                    <div className="p p--sm fw-700 prices-popover__caption">
                      {ele.subtype == 'Display Ad' ? 'Display Ad' : (ele.subtype == 'Direct Communication' ? 'Direct Communication' : 'Promotion')}
                    </div>
                    <dl className="p--sm prices-popover__list">
                      <React.Fragment key={ele.name}>
                        <dt>{ele.name}</dt>
                        <dd className={`${priceBlur} ${hideMediaBuyingPrice?"hidden":""}`}>{ele.price}</dd>
                      </React.Fragment>
                    </dl>
                  </React.Fragment>
                )
            }
          </>
        }

        <div className="mt--150 p ta--center tc--primary">
          <button onClick={onClick} className="qu-button-link">
            <b className="font-size-14">
              {calcMoreNumber() > 0 ? calcMoreNumber() + ' more services available' : 'View all services'}
            </b>
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Popover
      overlayClassName={`qu-price-popover ${className}`}
      content={contentMedia}
      placement="right"
      trigger="hover"
      getPopupContainer={(node: any) => node.parentNode}
      {...props}
    >
      {children}
    </Popover>
  )
}
export default QPricePopover
