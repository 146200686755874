import React from 'react'
import {
  Text,
  Cell,
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer
} from 'recharts'

import './styles.scss'

const chartColors = [
  '#8FCBA1',
  '#63A6C8',
  '#F7A57A',
  '#A0DCFF',
  '#F9E3A4',
  '#F0A9AB',
  '#B489C5',
  '#5CC073',
  '#60676A'
]

// custom labels
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    payload,
    value,
    chartArrow,
    textColor,
    textFontSize,
    weeklyReport,
    showSampleNumber,
    showOnlyPercentage,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius - 10) * cos
  const sy = cy + (outerRadius + 0) * sin
  const mx = cx + (outerRadius + 0) * cos
  const my = cy + (outerRadius + (textFontSize ? 30 : 10)) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 10
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {percent > 0 ? (
        <g>
          {
            chartArrow &&
            <>
              <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
              />
              <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            </>
          }
          {
            showOnlyPercentage
              ? <text
                style={{ fontSize: textFontSize || '13px' }}
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey + Number(textFontSize / 3)}
                textAnchor={textAnchor}
                fill={textColor || "#333"}
              >
                {
                  weeklyReport
                    ? showSampleNumber
                      ? `${(percent * 100).toFixed(2)}% (${payload.value})`
                      : `${(percent * 100).toFixed(2)}%`
                    : payload.name
                }
              </text>
              : <>
                <text
                  style={{ fontSize: textFontSize || '13px' }}
                  x={ex + (cos >= 0 ? 1 : -1) * 12}
                  y={ey}
                  textAnchor={textAnchor}
                  fill={textColor || "#333"}
                >
                  {weeklyReport ? payload.name : `${(percent * 100).toFixed(2)}%`}
                </text>
                <Text
                  style={{ fontSize: textFontSize ? textFontSize - 3 + 'px' : '10px' }}
                  x={ex + (cos >= 0 ? 1 : -1) * 12}
                  y={textFontSize ? ey : ey - 5}
                  dy={textFontSize ? 30 : 20}
                  textAnchor={textAnchor}
                  fill={textColor || "#999"}
                >
                  {
                    weeklyReport
                      ? showSampleNumber
                        ? `${(percent * 100).toFixed(2)}% (${payload.value})`
                        : `${(percent * 100).toFixed(2)}%`
                      : payload.name
                  }
                </Text>
              </>
          }
        </g>
      ) : (
        <g />
      )}
    </g>
  )
}

export interface Props {
  className?: string
  data?: any
  colors?: any
  height?: number
  minWidth?: number
  innerRadius?: number
  outerRadius?: number
  chartArrow?: boolean
  textColor?: string
  textFontSize?: string
  weeklyReport?: boolean
  showSampleNumber?: boolean
  minAngle?: number
  showOnlyPercentage?: boolean
}

const QPieChart = ({ className = '', data, colors, height = 300, minWidth = 300, innerRadius = 40, outerRadius = 60, chartArrow = true, textColor = '', textFontSize = '', weeklyReport = false, showSampleNumber = false, minAngle = 0, showOnlyPercentage = false }: Props) => {
  const keys = data.map((o, i) => i)

  return (
    <div className={`qu-chart qu-chart-pie ${className}`}>
      <ResponsiveContainer height={height} minWidth={minWidth}>
        <PieChart>
          <Pie
            activeIndex={keys}
            activeShape={(props) => renderActiveShape({ ...props, chartArrow, textColor, textFontSize, weeklyReport, showSampleNumber, showOnlyPercentage })}
            data={data}
            // cx={145}
            // cy={145}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            dataKey="value"
            minAngle={minAngle}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  colors
                    ? colors[index % colors.length]
                    : chartColors[index % chartColors.length]
                }
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}
export default QPieChart
