import React from 'react';

import { Menu } from 'antd'

export interface Props {
  name?: string
  icon?: React.ReactElement
  onClick?: any
  key?: number
}

const QMenuItem = (
  {
    name,
    icon,
    onClick,
    key,
    ...props
  }: Props) => (
    <Menu.Item key={key} onClick={onClick} {...props}>
        <span className="user-menu-icon">
          {icon}
        </span>
        {name}
    </Menu.Item>
  )

  export default QMenuItem