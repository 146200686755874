import React from 'react'

export const IconAgeGroup = ({ className = '' }) => (
  <svg
    fill="#1379ac"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    className={`icon-age-group ${className}`}
    aria-hidden="true"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z" />
  </svg>
)

export const IconAgeChildren = ({ className = '' }) => (
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    className={`icon-age-children ${className}`}
    aria-hidden="true"
  >
    <defs>
      <path id="c" d="M.0005.4892h73.952V44H.0005z" />
      <path
        id="e"
        d="M11.9797305.26165729c4 0 8.0497279.50285852 11 4 2.1130353 2.50471253 2.5768462 7.38907491 1.8363758 13.83630001-1.7731538 15.451-12.0557941 12.184-12.7424046 12.168-.6877902-.015-10.32157181 2.282-11.07306812-16.581 0 0-.47671685-5.73957939 1.9790969-9.42330001 2-3 5-4 9.00000002-4z"
      />
      <circle id="a" cx="31.5" cy="31.5" r="31.5" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <use fill="#E3ECF3" href="#a" />
      <g mask="url(#b)">
        <g transform="translate(-11 43.5426)">
          <mask id="d" fill="#fff">
            <use href="#c" />
          </mask>
          <path
            fill="#CF4827"
            d="M56.8140162 44.0002c-.4712246-5.284-1.0508438-11.315-1.0508438-11.315s-.289485 4.977-.6412808 11.315H26.4602733c-.260926-5.699-.5763739-9.964-.6899611-10.051-.1642146-.127-1.1864993 4.929-2.0354825 10.051H15.9765C17.9717402 30.7562 23 16.7870013 23 14.4574c0-5 12-13 12-13L46.5282092.4892s13.6678927 7.568 14.8459541 13.621c.9366076 4.825 2.0032454 18.732 2.6023367 29.89h-7.1624838z"
            mask="url(#d)"
          />
        </g>
        <path
          fill="#58472C"
          d="M20 18c-2-2-5-4-8-1s-2 7-1 11c.6666667 2.6666667.3333333 5.3333333-1 8 5.3333333.6666667 8-1.3333333 8-6s.3333333-7.3333333 1-8c2-1.33333333 2.3333333-2.66666667 1-4zM41.8 18c2-2 5-4 8-1s2 7 1 11c-.6666667 2.6666667-.3333333 5.3333333 1 8-5.3333333.6666667-8-1.3333333-8-6s-.3333333-7.3333333-1-8c-2-1.33333333-2.3333333-2.66666667-1-4z"
        />
        <g transform="translate(18.02027 12.738343)">
          <mask id="f" fill="#fff">
            <use href="#e" />
          </mask>
          <use fill="#EBA684" href="#e" />
          <path
            fill="#58472C"
            d="M12.9797305 12.2616573c2.2720761 0 2.520328-.4468259 4 0 .2224774.0671829-.2086245-4.07415714 0-4.00000001.3586667.12749072.6780117 3.85518101 1 4.00000001 1.0872613.4890119 4.1963594 1.4170532 5 2 .2510745.1821251.7827059 3.8309414 1 4C26.1527866 19.9523332 27 17.7072502 27 14c0-7.17970175-6.2680135-23-14-23S-1 6.82029825-1 14c0 3.936393.46439125 7.1519174 2.97973048 5.2616573.35567149-.2672847.88044575-4.9669641 1.31665112-5.2491381 1.08962853-.7048627 2.44220581-1.416906 4.11612758-1.9399234.24528785-.0766402.3079496-3.74303331.5672213-3.81093851.53208162-.13935616 0 4.00000001 1 4.00000001h4.00000002z"
            mask="url(#f)"
          />
        </g>
        <path
          fill="#EBA684"
          d="M37.2745 36.4544s-.271 6.354.344 7.73c.615 1.376-8.148 5.795-13.494.871 0 0 1.291-6.907.932-9.354-.359-2.448 12.218.753 12.218.753M42.5889999 28.4729s1.082-1.155 1.478.014c.397 1.169-1.677 6.394-1.542 6.628 0 0-.197.454-.457.204-.26-.249.521-6.846.521-6.846M17.9825003 28.2631c-.419 1.161 1.554 6.425 1.414 6.657 0 0 1.188.457 1.453.212.264-.244-1.389-6.855-1.389-6.855s-1.059-1.176-1.478-.014z"
        />
        <path
          fill="#EBA684"
          d="M31 53c-6 0-7.0893-6.7206-6.8753-7.9446.214-1.225 13.494-.871 13.494-.871S38 53 31 53z"
        />
        <path
          fill="#C18970"
          d="M25.9764 42.1863s2.346 2.48 5.66 1.819 4.785-2.179 4.785-2.179-1.308 3.281-5.139 3.581c-3.832.3-5.306-3.221-5.306-3.221"
        />
      </g>
    </g>
  </svg>
)

export const IconAgeElderly = ({ className = '' }) => (
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    className={`icon-age-elderly ${className}`}
    aria-hidden="true"
  >
    <defs>
      <circle id="a" cx="31.5" cy="31.5" r="31.5" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <use fill="#E3ECF3" href="#a" />
      <g mask="url(#b)">
        <path
          fill="#F26C21"
          d="M74.405 96.9359H60.169c-.937-6.816-2.088-14.597-2.088-14.597s-.576 6.421-1.275 14.597H-.162c-.519-7.352-1.145-12.854-1.371-12.967-.327-.163-2.359 6.36-4.046 12.967H-21c3.967-17.086 18.935-49.99 23.212-51.632 6.088-2.342 23.345-4.5 23.345-4.5h14.168s25.178 7.183 27.519 14.992c1.863 6.223 5.97 26.746 7.161 41.14"
        />
        <path
          fill="#CF9071"
          d="M39.8360503 33.9325s-.360673 6.354.4578285 7.73c.8185015 1.376-10.844147 5.795-17.9591212.871 0 0 1.7181878-6.907 1.2403958-9.354-.4777919-2.448 16.2608969-1.247 16.2608969-1.247"
        />
        <path
          fill="#EBA684"
          d="M41.6369 19.951s1.081-1.155 1.478.014c.397 1.17-1.678 6.394-1.542 6.629 0 0-.197.453-.457.203-.26-.249.521-6.846.521-6.846M21.4082 19.7557s-1.059-1.176-1.478-.015c-.419 1.162 1.554 6.426 1.414 6.657 0 0 .189.458.453.213.264-.245-.389-6.855-.389-6.855"
        />
        <path
          fill="#EBA684"
          d="M33.113 7.3094s9.875 1.556 8.371 17.006c-1.503 15.45-10.219 14.183-10.801 14.168-.583-.016-8.749.282-9.386-18.582 0 0-.278-13.949 11.816-12.592"
        />
        <path
          fill="#FFF"
          d="M21.4853 22.3206l1.414 1.89s-.04-4.143.186-4.793c.227-.65.574-2.635.764-3.103.19-.468 8.48-2.791 15.803.679 0 0-.323 2.979.379 3.244.702.266.358 4.853.358 4.853l1.162-1.312s1.779-11.886 1.34-13.125c-.439-1.239-1.839-8.687-16.863-4.266 0 0-4.683-.992-5.623 2.242-.94 3.233 1.08 13.691 1.08 13.691"
        />
        <path
          fill="#CF9071"
          d="M38.0666 41.6629s2.228.475-7.095 9.41c0 0-6.613-7.315-6.399-8.54.214-1.225 13.494-.87 13.494-.87"
        />
        <path
          fill="#A7755E"
          d="M26.424 36.6644s2.346 2.481 5.66 1.819c3.314-.661 4.785-2.178 4.785-2.178s-1.308 3.28-5.139 3.58c-3.831.3-5.306-3.221-5.306-3.221"
        />
        <path
          fill="#DCDCDC"
          d="M21.9416 41.4056l8.922 10.453s-.307-7.514-3.644-9.295l-5.278-1.158z"
        />
        <path
          fill="#FFF"
          d="M23.0771 37.2186l-5.158 4.384 8.909 10.677 1.473-8.809z"
        />
        <path
          fill="#DCDCDC"
          d="M41.4262 40.9925l-10.371 10.866s.307-7.513 3.644-9.295l6.727-1.571z"
        />
        <path
          fill="#FFF"
          d="M39.7614 37.0975l4.158 3.898-7.276 11.303-3.026-8.827z"
        />
        <path
          stroke="#CF9071"
          strokeLinecap="round"
          d="M29 35c1-1 4-1 5 0"
          opacity=".5"
        />
        <g fill="#FFF">
          <path d="M29.16666667 28c.77777777 0 1.55555555.41025641 2.33333333 1.23076923-1.55555556 2.46153846-3.88888889 3.28205128-7 2.46153846C25.66666667 31.69230769 28 28 29.16666667 28zM33.8333333 28c-.7777777 0-1.5555555.41025641-2.3333333 1.23076923 1.5555556 2.46153846 3.8888889 3.28205128 7 2.46153846C37.33333333 31.69230769 35 28 33.8333333 28z" />
        </g>
      </g>
    </g>
  </svg>
)

export const IconAgeInfants = ({ className = '' }) => (
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    className={`icon-age-infants ${className}`}
    aria-hidden="true"
  >
    <defs>
      <circle id="a" cx="31.5" cy="31.5" r="31.5" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <use fill="#E3ECF3" href="#a" />
      <g mask="url(#b)">
        <path
          fill="#247CC0"
          d="M30.1470115 47.2110838c-5.66413897 0-10.87028542 3.2169343-13.3037572 8.331757-.36083225.7587432-.69179539 6.5765381-.97915653 7.457161H46.2134871c-.2612687-.8002854-.5582428-6.548729-.8813095-7.2484208-2.4097826-5.2185061-7.6554112-8.5404972-13.4036642-8.5404972h-1.7815019z"
        />
        <path
          fill="#EDAE9B"
          fillRule="nonzero"
          d="M30.7828453 52.9229447h.5125808c1.6300966 0 3.1283571-.9259415 3.8287355-2.3977662.1040269-.2183533.1991273-.4538727.2818681-.7072449h-8.7344745c.0751876.2303696.160675.4459763.2537155.6471634.6935119 1.5016938 2.2031021 2.4578477 3.8575746 2.4578477z"
        />
        <path
          fill="#FFCEBF"
          fillRule="nonzero"
          d="M19.44945947 35.695113c0-6.32044222 5.12364693-11.44383863 11.44383863-11.44383863 6.3201917 0 11.4438386 5.12339641 11.4438386 11.44383863l-.000501 3.029443v2.9207118c0 4.5526825-3.6592826 8.2492944-8.1894172 8.2873754-.0243017.0007516-.0476012.0007516-.0716524.0007516h-6.3652876c-.0230491 0-.0473507 0-.0706503-.0007516-4.52938302-.038081-8.19016873-3.7346929-8.19016873-8.2873754V35.695113z"
        />
        <path
          fill="#FFCEBF"
          fillRule="nonzero"
          d="M19.5817408 36.2793556h-.14430691C18.09132101 36.2793556 17 37.3741841 17 38.724556c0 1.3506225 1.09132101 2.445451 2.43743389 2.445451h.14430691v-4.8906514z"
        />
        <path
          fill="#FFBFAB"
          fillRule="nonzero"
          d="M42.2046049 36.2793556h.1445574c1.3458624 0 2.4371834 1.0948285 2.4371834 2.4452004 0 1.3506225-1.091321 2.445451-2.4371834 2.445451h-.1445574v-4.8906514z"
        />
        <path
          fill="#FF9A9A"
          fillRule="nonzero"
          d="M26.93610191 41.1890699c0 1.5639131-1.26758636 2.8314997-2.83149948 2.8314997-1.56391313 0-2.83209568-1.2675866-2.83209568-2.8314997 0-1.563913 1.26818255-2.8320957 2.83209568-2.8320957 1.56391312 0 2.83149948 1.2681827 2.83149948 2.8320957zM39.8567366 41.1890699c0 1.5639131-1.2675866 2.8314997-2.8314997 2.8314997-1.563913 0-2.8314995-1.2675866-2.8314995-2.8314997 0-1.563913 1.2675865-2.8320957 2.8314995-2.8320957 1.5639131 0 2.8314997 1.2681827 2.8314997 2.8320957z"
          opacity=".1"
        />
        <path
          fill="#3B4048"
          d="M27.4402041 24.39733502c-.2152077.32018099-.3665295.68395466-.4319186 1.07979653-.61430649 3.70638266 4.216217 5.60867843 7.1123765 5.1597236.3149198-.04860334.4870358-.39534078.3364656-.67618806-.3660284-.6819504-.6701753-1.25967908-.797947-2.01403344-.1385447-.81899181-.12226-1.73844733-.3269454-2.51409697-.3439816-1.30352233-1.5054518-2.24702894-2.831522-2.40937422-1.2028081-.14706278-2.4173913.41738773-3.0605091 1.37417256z"
        />
      </g>
    </g>
  </svg>
)

export const IconAgeMiddle = ({ className = '' }) => (
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    className={`icon-age-middle ${className}`}
    aria-hidden="true"
  >
    <defs>
      <path id="c" d="M.00008374.10166016H102.60726V65.3170732H.00008374z" />
      <path id="e" d="M.7181237.11556098h24.5271908V21.722365H.7181237z" />
      <path
        id="f"
        d="M23.8156935 18.743739l-1.5198781 2.0323659s.0427074-4.4566342-.2009756-5.1550244c-.2445203-.6983903-.6171626-2.8329187-.8214878-3.3361951-.2043252-.5032765-9.1201057-3.0020732-16.99583737.7293739 0 0 .34668293 3.2038862-.4086504 3.4894391-.75449594.2855528-.38436586 5.2186666-.38436586 5.2186666L2.2342626 20.3121862S.32080732 7.5276252.7931 6.19616179c.47313008-1.33313821 1.97877236-9.34369106 18.136374-4.58810569 0 0 5.0361138-1.06684553 6.047691 2.41086992C25.9879049 7.49580407 23.8156935 18.743739 23.8156935 18.743739"
      />
      <circle id="a" cx="31.5" cy="31.5" r="31.5" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <use fill="#E3ECF3" href="#a" />
      <g mask="url(#b)">
        <g transform="translate(-25 43.242163)">
          <mask id="d" fill="#fff">
            <use href="#c" />
          </mask>
          <path
            fill="#247CC0"
            d="M102.607344 60.4713837H87.296352c-1.0073902-7.3305853-2.2459024-15.6995447-2.2459024-15.6995447s-.6188374 11.7520488-1.3716585 20.5455691H22.4113764c-.5585447-7.9075528-1.2326504-18.6697967-1.4746585-18.7912195-.3525447-.1758536-2.5364797 6.8390325-4.351122 13.9451951H.00008374C4.26579106 42.0955138 19.3021163 6.47174959 23.9019455 4.70483902 30.4495634 2.18678211 50.0714821.10166016 50.0714821.10166016h15.2381382s27.0781138 7.725 29.5961708 16.12410574c2.0030569 6.6933252 6.4211709 28.7646341 7.7015529 44.2456178"
            mask="url(#d)"
          />
        </g>
        <path
          fill="#EBA684"
          d="M33.1982659 7.32112195S43.818152 8.9942439 42.2011358 25.6107398c-1.6170163 16.616496-10.9900163 15.2540488-11.6172277 15.2373009-.626374-.016748-9.4090081.3031382-10.094-19.9845122 0 0-.2989512-15.00199191 12.7083578-13.54240655"
        />
        <path
          fill="#EBA684"
          d="M38.155413 35.9537821s-.2905772 6.8348455.3701301 8.3136911c.6615447 1.4796829-8.763374 6.2327561-14.5121138.9362114 0 0 1.3884065-7.4277236 1.0023658-10.0596667-.3860406-2.6327805 13.1396179.8097642 13.1396179.8097642M42.3655171 20.9169545s1.1631463-1.2418618 1.5893821.0150731c.4262358 1.2577724-1.8037561 6.8767155-1.6580488 7.1287724 0 0-.2126992.4873659-.4915528.2193984-.2788537-.2688049.5602195-7.3632439.5602195-7.3632439M20.6096561 20.7067675s-1.1388618-1.2644716-1.5893821-.0159106c-.4505203 1.2493984 1.6706097 6.9102114 1.519878 7.1597561 0 0 .2034878.4915529.4882033.2286098.283878-.2629431-.4186992-7.3724553-.4186992-7.3724553"
        />
        <g transform="translate(18.544715 4.721837)">
          <mask id="g" fill="#fff">
            <use href="#f" />
          </mask>
          <use fill="#3B4048" href="#f" />
          <path
            stroke="#FFF"
            d="M19.4552846 12.2781626c1-1 2-1 4-1 1.3333333 0 2.6666666-1.33333333 4-4M16.4552846 12.2781626c0-1 1-4 5-4 2.6666666 0 4.3333333-1.33333333 5-4"
            mask="url(#g)"
            opacity=".60000002"
          />
        </g>
        <path
          fill="#EBA684"
          d="M38.5256268 44.2676407s2.3957968.5116504-7.6312114 10.1207967c0 0-7.1111869-7.8673577-6.8817398-9.1845854.231122-1.318065 14.5129512-.9362113 14.5129512-.9362113"
        />
        <path
          fill="#C18970"
          d="M26.0043439 38.8919618s2.5230813 2.6679512 6.0870488 1.9561626c3.5639675-.7109512 5.1466504-2.3430407 5.1466504-2.3430407s-1.4068293 3.5287968-5.5276667 3.8511952c-4.12.3232357-5.7060325-3.4643171-5.7060325-3.4643171"
        />
        <path
          fill="#3B4048"
          d="M21 26c0 4.6666667.3333333 8.3333333 1 11 2 3.3333333 5 5.3333333 9 6 6 0 9.3333333-2.3333333 10-7 .6666667-4.6666667.6666667-8 0-10-2 6.6666667-3.3333333 10-4 10-1 0-3-3-5-3s-4 2-5 3c-.6666667.6666667-2.6666667-2.6666667-6-10z"
        />
      </g>
    </g>
  </svg>
)

export const IconAgeTeens = ({ className = '' }) => (
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    className={`icon-age-teens ${className}`}
    aria-hidden="true"
  >
    <defs>
      <path id="c" d="M.29489128.58031998H57.0841855V90.9999967H.29489128z" />
      <path id="e" d="M0 .00962h26.7765844v33.1967988H0z" />
      <path id="g" d="M.34962467.28452995H57.4885979V69.2040274H.34962467z" />
      <circle id="a" cx="31.5" cy="31.5" r="31.5" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <use fill="#E3ECF3" href="#a" />
      <g mask="url(#b)">
        <g transform="translate(-13 8)">
          <g transform="translate(14.299999 39.668199)">
            <mask id="d" fill="#fff">
              <use href="#c" />
            </mask>
            <path
              fill="#FABB95"
              d="M46.7310983 39.5231186c4.0507998-10.2179997 14.0425995-27.571699 8.9283997-30.96339891C48.9917982 4.13841985 29.3175989.58031998 29.3175989.58031998l-.0182.33019999S9.23129966 1.45131995 2.12549992 5.1251198c-5.4508998 2.8183999 2.7273999 22.01809919 5.64849979 32.61439879.70069998 2.5427999.51739999 13.6746995-.34059998 23.5871992-.21319999 2.4439999-2.06439993 13.6915994-1.96819993 18.3663993.0286 1.3948999 14.4234995 10.8406996 18.9461993 11.3073996l.0169-.3302c4.5460998.0247 21.1964992-7.6985997 21.3758992-9.0830997.6019-4.6357998-.8332999-16.0094994-.7787-18.4638993.2184-9.9462997.7345-21.1483992 1.7056-23.6001991"
              mask="url(#d)"
            />
          </g>
          <path
            fill="#FABB95"
            d="M52.0649981 37.5241086c-.3432-2.0656999-.9282-12.2160995-.9282-12.2160995l-6.6884997-.3627-.0195.3393-6.6884998-.3627s-1.6808999 10.0281996-2.2450999 12.0431995c-.5629 2.0163-6.2347998 4.7969999-6.2347998 4.7969999l14.2336995.7721999.0182-.3392999 14.2336995.7721999s-5.3377998-3.3786999-5.6809998-5.4430998"
          />
          <path
            fill="#F7A27D"
            d="M51.7685981 34.6517587c-.2574-3.1134999-.5252-7.5399997-.6071-8.9153996l-.4082-.4498-6.3049997-.3419-.0195.3406-6.6884998-.3627s-1.6795999 10.0268996-2.2450999 12.0431995c-.5629 2.0163-6.2347998 4.7956999-6.2347998 4.7956999l.6721.0377c6.4440998-1.3494 12.8517995-2.6948999 13.7149995-2.8924999 1.8043999-.4134 8.1210997-4.2548999 8.1210997-4.2548999z"
          />
          <g transform="translate(0 44.868198)">
            <mask id="f" fill="#fff">
              <use href="#e" />
            </mask>
            <path
              fill="#EFEEEE"
              d="M15.1394094.00962s-4.5486998 3.34619988-6.39469972 7.49969972C6.89610975 11.6615196-.00039 21.6650192-.00039 21.6650192s.2132 4.5967998 4.99199982 7.6855997c4.77879982 3.0900999 7.21369978 3.8557999 7.21369978 3.8557999S26.358409 12.2673196 26.758809 10.7528196c.4004-1.51579994-6.0397998-7.17209973-11.6193996-10.7431996"
              mask="url(#f)"
            />
          </g>
          <ellipse cx="34.5" cy="18.5" fill="#F7A27D" rx="2.5" ry="3.5" />
          <ellipse cx="53.5" cy="18.5" fill="#F7A27D" rx="2.5" ry="3.5" />
          <path
            fill="#FABB95"
            d="M54.034758 17.4337794c-.5044 8.8477996-6.3777997 17.3549993-10.9394996 17.0949993-4.3588998-.2483-9.4314996-9.3781996-8.9270997-18.2272993.5044-8.84909967 5.3598999-15.76769942 10.8458996-15.45569943 5.4859998.31199999 9.5250997 7.73889972 9.0206997 16.58799943"
          />
          <g transform="translate(14.299999 39.368199)">
            <mask id="h" fill="#fff">
              <use href="#g" />
            </mask>
            <path
              fill="#F6F6F6"
              d="M57.3215479 9.26362962c-.5863-1.64319994-5.5704998-3.03029989-9.9371996-4.64619983-3.8479999-1.42479994-7.2695998-3.15379988-7.2695998-3.15379988L28.9100489 7.0978297 18.3800493.28452995s-3.5879998 1.34679995-7.5685997 2.34649992C6.29524977 3.76592983 1.27594995 4.64992979.42964998 6.17612974-.42834998 7.72312968 5.87924978 38.6657285 6.63324976 41.3086284c.75139997 2.6442-3.02249989 21.3446993-1.05299996 26.8969991l19.9380993.9944999v.0026l.0377.0013h.0039l19.4973992-.4511c2.1177-6.1294997 1.2558-23.0294991 2.2906-25.576199 1.0347999-2.5466999 11.5894995-29.3916989 9.9735996-33.91309878"
              mask="url(#h)"
            />
          </g>
          <path
            fill="#8E3C26"
            d="M34.0668888 18.0754593c-.3848-.9879999-1.6354-5.1661998-1.2077-6.9939997.4277-1.82779994 2.3971999-5.7407998 3.0471999-6.91209975C36.5550887 2.99805989 41.8967885.08216 42.6754884.04576c.7774-.0351 2.8547999-.1599 4.4485999.25349999 1.5950999.41339998 4.3549998 1.93959993 4.9074998 3.58149987.5538 1.64189994 2.0474999 3.57889987 2.4959999 4.89319982.4498 1.31299992 1.1648 4.65529982 1.0088 5.12979982-.156.4745-1.0946 4.4459998-1.1778 4.7644998-.0819.3172-.598 1.2025-.598 1.2025s-2.1982999-8.6020997-2.2177999-9.4080997c-.0182-.80729995-13.5472995-1.81869992-14.4169995-1.30519994-.8709999.51349999-3.0588998 8.91799964-3.0588998 8.91799964"
          />
        </g>
      </g>
      <g fill="#F26C21" mask="url(#b)">
        <g transform="translate(19 6)">
          <path d="M13.8018327 0c5.367987 0 9.7196002 4.35161322 9.7196002 9.71960016v4.85980004H0V9.71960016C0 4.35161322 4.35161322 0 9.71960016 0h4.08223254zM8.27200016 7.9652183c-2.28424977 0-4.13600008 1.85175031-4.13600008 4.1360001v1.034h8.27200012v-1.034c0-2.28424979-1.8517503-4.1360001-4.13600004-4.1360001z" />
          <rect
            width="29.9363691"
            height="1.94392003"
            y="12.6354802"
            rx=".97196002"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const IconAgeYoung = ({ className = '' }) => (
  <svg
    width="63"
    height="63"
    viewBox="0 0 63 63"
    className={`icon-age-young ${className}`}
    aria-hidden="true"
  >
    <defs>
      <circle id="a" cx="31.5" cy="31.5" r="31.5" />
      <path id="c" d="M.0005.4892h73.952V44H.0005z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <use fill="#E3ECF3" href="#a" />
      <g mask="url(#b)">
        <path
          fill="#58472C"
          d="M36.2742 54.9558l.649-47.58C16.9572 5.2658 16 17 15 21c-.6666667 2.6666667-1.3252667 13.9852667-1.9758 33.9558h23.25z"
        />
        <path
          fill="#58472C"
          d="M35 35l3.4932 21.9988 11.625 3.012-1.879-36.459s1.249-12.472-9.631-16.005l-3.292 12.595S34.577 21.874 35 29v6z"
        />
        <g transform="translate(-11 42.5426)">
          <mask id="d" fill="#fff">
            <use href="#c" />
          </mask>
          <path
            fill="#CF4827"
            d="M73.9525 44.0002h-11.035c-.726-5.284-1.619-11.315-1.619-11.315s-.446 4.977-.988 11.315h-44.158c-.402-5.699-.888-9.964-1.063-10.051-.253-.127-1.828 4.929-3.136 10.051H.0005c3.074-13.244 8.808-34.492 12.124-35.765 4.719-1.815 23.964-7.746 23.964-7.746h10.982s19.517 5.568 21.332 11.621c1.443 4.825 4.627 20.732 5.55 31.89"
            mask="url(#d)"
          />
        </g>
        <path
          fill="#EBA684"
          d="M32.6651 8.8313s9.875 1.556 8.371 17.005c-1.503 15.451-10.219 14.184-10.801 14.168-.583-.015-8.749.282-9.386-18.581 0 0-.278-13.949 11.816-12.592"
        />
        <path
          fill="#EBA684"
          d="M37.2745 35.4544s-.271 6.354.344 7.73c.615 1.376-8.148 5.795-13.494.871 0 0 1.291-6.907.932-9.354-.359-2.448 12.218.753 12.218.753M41.189 21.4729s1.082-1.155 1.478.014c.397 1.169-1.677 6.394-1.542 6.628 0 0-.197.454-.457.204-.26-.249.521-6.846.521-6.846M20.9605 21.2771s-1.059-1.176-1.478-.014c-.419 1.161 1.554 6.425 1.414 6.657 0 0 .188.457.453.212.264-.244-.389-6.855-.389-6.855"
        />
        <path
          fill="#EBA684"
          d="M37.6187 43.1844s2.22 3.598-7.103 12.532c0 0-6.605-10.437-6.391-11.661.214-1.225 13.494-.871 13.494-.871"
        />
        <path
          fill="#C18970"
          d="M25.9764 38.1863s2.346 2.48 5.66 1.819 4.785-2.179 4.785-2.179-1.308 3.281-5.139 3.581c-3.832.3-5.306-3.221-5.306-3.221"
        />
        <path
          fill="#58472C"
          d="M40.0652 33.7791c.894-6.556.634-12.47-4.351-14.66-3.363-1.478-11.91-2.016-11.91-7.584 0-4.403 4.357-5.307 7.935-6.251 5.408-1.423 13.396 2.681 14.704 8.305 0 0 2.736 15.027-6.378 20.19"
        />
      </g>
    </g>
  </svg>
)
