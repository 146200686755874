import React from 'react'

import './styles.scss'

export interface Props {
  className?: string
  data?: any
  paymentStatus?: any
  onClick?: (e: any, name?: string) => void
}

const RequestCardItem = ({
  className = '',
  data,
  paymentStatus,
  onClick
}: Props) => {
  return (
    <li
      className={`qu-request-card ${className} ${data.status}`}
      onClick={onClick}
    >
      <div className="request-card-header">
        <div className="request-card-description">
          <div className="request-card-image">
            {data.product.image && (
              <img src={data.product.image} alt="product-image" />
            )}
          </div>
          <div className="request-card-info">
            <div className="request-card-name">{data.product.title}</div>
            <div className="request-card-received">{data.product.received}</div>
          </div>
        </div>
      </div>

      <div className="tc-list__sub-header">
        <div className="sub-header__status">
          <div className="sub-header__status-label">Request status</div>
          <span className={`status-label ${data.status.toLowerCase()}`}>
            {data.status}
          </span>
        </div>

        {data?.payment?.status && (
          <div className="sub-header__status">
            <div className="sub-header__status-label">Payment status</div>
            <span
              className={`status-label ${
                paymentStatus[data.payment.status] &&
                paymentStatus[data.payment.status].classStyle
              }`}
            >
              {paymentStatus[data.payment.status] &&
                paymentStatus[data.payment.status].label}
            </span>
          </div>
        )}
      </div>

      <div className="request-card-body">
        <div className="request-card-column">
          <div className="request-card-label">Service</div>
          <div className="request-card-value">{data.service.title}</div>
        </div>
        <div className="request-card-column">
          <div className="request-card-label">Date & Duration</div>
          <div className="request-card-value">
            {data.service.startDate}, {data.service.duration}
          </div>
        </div>
        <div className="request-card-column">
          <div className="request-card-label">Potential</div>
          <div className="request-card-value">
            {data.service.revenue} Earnings
          </div>
        </div>
      </div>
    </li>
  )
}
export default RequestCardItem
