import React from 'react'
import './styles.scss'

export interface Props {
  title?: string
  children?: any
  className?: string
}

const ServicesSummary = (
  {
    title,
    children,
    className = ''
  }
    : Props) => (
    <div className={`SS ${className}`}>
      {
        title &&
        <div className="SS__header">
          <h3 className="SS__title">{title}</h3>
        </div>
      }
      <div className="SS__body">
        {children}
      </div>
    </div>
  )

export default ServicesSummary

