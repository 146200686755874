import React, { ReactElement } from 'react'

import './styles.scss'

export interface Props {
  className?: string
  title: string
  children?: ReactElement | null
  childrenFilter?: ReactElement | null
  onClickCancel?: (value: any) => void
  onClickBack?: (value: any) => void
}

const closeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
    />
  </svg>
)

const backIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
    />
  </svg>
)

const QBriefHeader = ({
  className,
  title = 'Header',
  children,
  childrenFilter,
  onClickCancel,
  onClickBack,
  ...props
}: Props) => {
  return (
    <header className={`qu-brief-header ${className}`} {...props}>
      <div className="header-wrapper">
        <button className="qu-brief-close" onClick={onClickCancel}>
          {closeIcon}
        </button>
        {onClickBack && (
          <button className="qu-brief-back" onClick={onClickBack}>
            {backIcon}
          </button>
        )}
        <div className="header-content">
          <h3>{title}</h3>
          {children}
        </div>
      </div>
      {childrenFilter}
    </header>
  )
}
export default QBriefHeader
