import React from 'react'

import './styles.scss'

export interface Props {
  title: string
  text: string
  checked: boolean
  onChange: () => void
  className?: string
  cardType?: string
  inputName: string
  cardImage?: string
  isComingSoon?: boolean
}

const QSelectCard = ({
  className = '',
  title = 'SelectCard',
  text = 'SelectCardText',
  cardType = 'checkbox',
  inputName = 'card',
  cardImage = 'http://placehold.it/464x348&amp;text=4/3',
  checked = false,
  onChange,
  isComingSoon = false
}: Props) => {
  return (
    <div className={`card card--hoverable ${isComingSoon ? 'card--unselectable' : 'card--selectable'} ${className}`}>
      <input
        checked={checked}
        onChange={onChange}
        type={cardType}
        name={inputName}
        className="card__input"
      />
      <div className="card__checkmark" />
      <div className="card__head card__head--43">
        <img className="card__image" src={cardImage} alt="" />
      </div>
      <div className="card__cont ta--center">
        <h5 className="card__title">{title} {isComingSoon ? <span className="focus_group_coming_soon">&nbsp;COMING SOON&nbsp;</span> : ''}</h5>
        <p className="p p--sm mb-0 tc--light card__summary">{text}</p>
      </div>
    </div>
  )
}
export default QSelectCard
