import React from 'react'
import { IconCrossCircle, IconEye } from '../Icons/IconsCommon'

interface Props {
  fileName?: string
  className?: string
  disabled?: boolean
  viewOnly?: boolean
  viewOnlyText?: string
  disableView?: boolean
  showRemoveButton?: boolean
  onView?: (e: React.MouseEvent) => void
  onRemove?: (e: React.MouseEvent) => void
}

const AlternativeFileView = ({
  fileName = '',
  className = '',
  disabled = false,
  viewOnly = false,
  viewOnlyText = 'View',
  disableView = false,
  showRemoveButton = true,
  onView = () => { },
  onRemove = () => { }
}: Props) => {
  return viewOnly ? (
    <div
      className={`only-view-icon ${disabled || disableView ? 'disabled' : ''
        } ${className}`}
      onClick={onView}
    >
      <IconEye />
      <span>{fileName ? `${viewOnlyText} ${fileName}` : viewOnlyText}</span>
    </div>
  ) : (
    <div
      onClick={onView}
      className={`uploaded-label status-label accepted ${className}`}
      title="Open file"
    >
      <span>{fileName ? `${fileName} Uploaded` : 'Uploaded'}</span>
      {showRemoveButton && (
        <span
          className="icon-link-cancel"
          onClick={onRemove}
          title="Remove file"
        >
          <IconCrossCircle />
        </span>
      )}
    </div>
  )
}

export default AlternativeFileView
