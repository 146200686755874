import React, { ReactElement } from 'react'
import { Badge, Col, Popover, Row } from 'antd'

import './styles.scss'

export interface Props {
  menu: ReactElement | null
  updated?: boolean
  className?: string
  badgeNumber?: number
  samplesCurrent?: string
  samplesNeeded?: string
  budgetCurrent?: string
  budgetNeeded?: string
}

const cartIcon = (
  <svg
    fill="currentColor"
    height="19"
    viewBox="0 0 24 19"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m232.575 67.2915098c.4245-.0275686.7845-.289098.951-.6568039l3.375-7.4509804-.00225-.0007451c.06375-.1397059.10125-.2939412.10125-.4574902 0-.6173137-.503625-1.1176471-1.125-1.1176471h-16.65825l-.655125-1.8593921-.004125.0014902c-.153375-.4355098-.566625-.7499412-1.0575-.7499412h-3.375c-.621375 0-1.125.5003333-1.125 1.1176471 0 .6173137.503625 1.117647 1.125 1.117647h2.577l4.33125 12.2941177h-2.03325c-1.24275 0-2.25 1.0006666-2.25 2.2352941 0 1.2346274 1.00725 2.2352941 2.25 2.2352941s2.25-1.0006667 2.25-2.2352941h10.5c0 1.2346274 1.00725 2.2352941 2.25 2.2352941s2.25-1.0006667 2.25-2.2352941c0-1.2346275-1.00725-2.2352941-2.25-2.2352941h-10.58325l-.561375-1.5941373zm-10.491-1.5449608-2.079375-5.9034117h14.13l-2.3835 5.263z"
      transform="translate(-213 -55)"
    />
  </svg>
)

const QBriefCart = ({
  menu,
  updated,
  className = '',
  samplesCurrent,
  samplesNeeded,
  badgeNumber,
  budgetCurrent,
  budgetNeeded,
  ...props
}: Props) => {
  return (
    <Popover
      className={`cart-menu-popover ${className}`}
      overlayClassName="cart-menu-wrapper"
      content={menu}
      trigger="click"
      placement="bottomRight"
      getPopupContainer={(node: any) => node.parentNode}
    >
      <div className={`cart-menu ${updated && 'updated'}`}>
        <Row justify="space-around" align="middle">
          <Col>
            {budgetNeeded !== '' &&
              <div className="cart-info-flex">
                <span className="cart-info-text">Budget allocated: </span>
                <span className="cart-info-number">{budgetCurrent} of {budgetNeeded}</span>
              </div>}
            {samplesNeeded !== '' &&
              <div className="cart-info-flex">
                <span className="cart-info-text">Samples allocated: </span>
                <span className="cart-info-number">{samplesCurrent} of {samplesNeeded}</span>
              </div>}
          </Col>
          <Col>
            <Badge count={badgeNumber}>
              <span className="cart-menu-icon">{cartIcon}</span>
            </Badge>
          </Col>
        </Row>
      </div>
    </Popover>
  )
}
export default QBriefCart
