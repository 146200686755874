import React from 'react'
import { Checkbox } from 'antd'

import './styles.scss'

const QCheckbox = ({ children = 'Checkbox', className = '', ...props }) => {
  return (
    <Checkbox className={`qu-checkbox ${className}`} {...props}>
      {children}
    </Checkbox>
  )
}
export default QCheckbox
