import React from 'react'

import './styles.scss'

interface ICampaignTableProduct {
  title: string
  image: string | null
  startDate: string
  isExistCouponCode: boolean
  discountText: string | null
}

interface ICampaignTableService {
  types: string[]
  productStartDate: string
  mediaStartDate: string
  productDuration: string
  mediaDuration: string
  quantity: number
  revenue: string
}

interface ICampaignTable {
  key: number
  product: ICampaignTableProduct
  service: ICampaignTableService
  status: string
}

export interface Props {
  data: ICampaignTable
  className?: string
  onClick?: (e: any, name?: string) => void
}

const CampaignsCardItem = ({ className = '', data, onClick }: Props) => {
  const isDistribution = (service: string) =>
    service === 'Product Sample' || service === 'Printed Insert'

  const isMediaBuy = (service: string) =>
    service !== 'Product Sample' && service !== 'Printed Insert'

  const distributions = data.service.types.filter(isDistribution)
  const mediaBuys = data.service.types.filter(isMediaBuy)

  return (
    <li className={`qu-request-card ${className}`} onClick={onClick}>
      <div className="request-card-header">
        <div className="request-card-description">
          <div className="request-card-image">
            {data.product.image && (
              <img src={data.product.image} alt="product-image" />
            )}
          </div>
          <div className="request-card-info">
            <div className="request-card-name">{data.product.title}</div>
            <div className="request-card-received">
              {data.product.startDate}
            </div>
            {data.product.discountText && (
              <div className="text-green line-height-16 font-size-12">
                {data.product.discountText}
              </div>
            )}
          </div>
        </div>
        <div className="request-card-status">
          <span className={`status-label ${data.status}`}>{data.status}</span>
        </div>
      </div>
      <div className="request-card-body">
        <div className="request-card-column">
          <div className="request-card-label">Services</div>
          {distributions.length > 0 && (
            <div className="request-card-value">Distribution</div>
          )}
          {mediaBuys.length > 0 && (
            <div className="request-card-value">Media Buy</div>
          )}
        </div>
        <div className="request-card-column">
          <div className="request-card-label">Date & Duration</div>
          {distributions.length > 0 && (
            <div className="request-card-value">
              {data.service.productStartDate}, {data.service.productDuration}
            </div>
          )}
          {mediaBuys.length > 0 && (
            <div className="request-card-value">
              {data.service.mediaStartDate}, {data.service.mediaDuration}
            </div>
          )}
        </div>
        <div className="request-card-column">
          <div className="request-card-label">Earnings</div>
          <div className="request-card-value">{data.service.revenue}</div>
          {/* {data.service.status === 'unpaid' ? ( */}
          {/*  <div className="p--xs"> */}
          {/*    <a href="#" className="text-link tc--primary text-nowrap"> */}
          {/*      Submit Invoice */}
          {/*    </a> */}
          {/*  </div> */}
          {/* ) : ( */}
          {/*  <> */}
          {/*    <div className="p--xs tc--light">Paid May 25, 2020</div> */}
          {/*    <div className="p--xs"> */}
          {/*      <a href="#" className="text-link tc--secondary"> */}
          {/*        View Invoice */}
          {/*      </a> */}
          {/*    </div> */}
          {/*  </> */}
          {/* )} */}
        </div>
      </div>
    </li>
  )
}
export default CampaignsCardItem
