import React from 'react'
import { Progress } from 'antd'

import './styles.scss'

export interface Props {
  value: number
  title: string
  className?: string
}

const QMetricsProgress = ({
  className = '',
  value = 0,
  title = 'No title',
  ...props
}) => {
  return (
    <div className={`qu-metrics-progress ${className}`}>
      <div className="progress-body">
        <Progress
          type="circle"
          percent={value}
          format={() => `${value}%`}
          {...props}
        />
      </div>
      <div className="progress-footer">{title}</div>
    </div>
  )
}
export default QMetricsProgress
