import React from 'react'

import './styles.scss'

import QButton from '../Buttons/QButton'

export interface Props {
  className?: string
  bill: IBillListElement
  quotationNode: React.ReactNode
  poNode: React.ReactNode
  invoiceNode: React.ReactNode
  bankTransferNode: React.ReactNode
  paymentStatus: any
  quotationText?: string
  invoiceText?: string
  poText?: string
  bankTransferText?: string
  payButtonShow?: boolean
  payButtonText?: string
  onCardClick?: (event: any) => void
  onPayClick?: (event: any) => void
}

interface IBillListElement {
  key: number
  brief: { name: string; image: string | null }
  payment: IBillPayment
}

interface IBillPayment {
  id: number
  status: string
  briefId: number
  updatedBy: number
  createdAt: string
  updatedAt: string
  quotation: string | null
  po: IBillFileField
  bankTransfer: IBillFileField
}

interface IBillFileField {
  isUploading: boolean
  uploadingError: string
  isRemoving: boolean
  removingError: string
  files: IUploadFile[]
}

interface IUploadFile {
  uid?: string
  name: string
  status: FileStatus
  percent?: number
  thumbUrl?: string
  url: string
}

type FileStatus = 'error' | 'success' | 'done' | 'uploading' | 'removed'

const BillCardItem = ({
  className = '',
  bill,
  quotationNode,
  poNode,
  invoiceNode,
  bankTransferNode,
  paymentStatus,
  quotationText = 'Quotation',
  invoiceText = 'Invoice',
  poText = 'PO',
  bankTransferText = 'Bank Transfer',
  payButtonShow = true,
  payButtonText = 'Pay',
  onCardClick = (event: any) => { },
  onPayClick = (event: any) => { }
}: Props) => {
  return (
    <li className={`qu-request-card ${className}`} onClick={onCardClick}>
      <div className="request-card-header">
        <div className="request-card-description">
          <div className="request-card-image">
            {bill.brief.image && (
              <img src={bill.brief.image} alt="product-image" />
            )}
          </div>
          <div className="request-card-info">
            <div className="request-card-name">
              {bill.brief.name || 'Unnamed Brief'}
            </div>
            <div className="request-card-received">
              {bill.payment.createdAt}
            </div>
          </div>
        </div>
        <div className="request-card-status">
          <span
            className={`status-label ${paymentStatus[bill.payment.status] &&
              paymentStatus[bill.payment.status].classStyle
              }`}
          >
            {paymentStatus[bill.payment.status] &&
              paymentStatus[bill.payment.status].label}
          </span>
        </div>
      </div>
      <div className="request-card-body mb-24">
        <div className="request-card-column">
          <div className="request-card-label mb-8">{quotationText}</div>
          <div className="request-card-value">{quotationNode}</div>
        </div>
        <div className="request-card-column">
          <div className="request-card-label mb-8">{invoiceText}</div>
          <div className="request-card-value">{invoiceNode}</div>
        </div>
        <div className="request-card-column">
          <div className="request-card-label mb-8">{poText}</div>
          <div className="request-card-value">{poNode}</div>
        </div>
        <div className="request-card-column">
          <div className="request-card-label mb-8">{bankTransferText}</div>
          <div className="request-card-value">{bankTransferNode}</div>
        </div>
      </div>
      {payButtonShow && <QButton
        className="full-width"
        type="primary"
        size="small"
        onClick={onPayClick}
      >
        {payButtonText}
      </QButton>}
    </li>
  )
}
export default BillCardItem
