import React from 'react'
import { DatePicker } from 'antd'

import './styles.scss'

const { RangePicker } = DatePicker

const QRangePicker = ({ className = '', ...props }) => {
  return (
    <RangePicker
      className={`qu-datepicker range ${className}`}
      separator={<span style={{ color: '#d9d9d9' }}>—</span>}
      {...props}
    />
  )
}

export default QRangePicker
