import React, { useState } from 'react'
import { LinkOutlined } from "@ant-design/icons";
import { Carousel, Col, Row, Tag } from 'antd'

import QButton from '../Buttons/QButton'
import { IconsCommon } from '../..'
import './styles.scss'

export interface Props {
  promotionThumbnail?: string[]
  promotionName?: string
  promotionType?: string[]
  promotionTypeHeading?: string
  promotionPrice?: string
  promotionDate?: string
  promotionFiles?: string[]
  promotionFilesText?: string
  buttonText?: string
  showAllPromotionsButton?: boolean
  showAllPromotionsButtonText?: string
  handleShowAllPromotion?: () => void
  onCreateCampaign?: (value: any) => void
}

const QPromotionsCard = ({
  promotionThumbnail = ['https://via.placeholder.com/64x64'],
  promotionName,
  promotionType,
  promotionTypeHeading = 'Services Bundle',
  promotionPrice,
  promotionDate,
  promotionFiles,
  promotionFilesText = 'File',
  buttonText = 'create campaign',
  showAllPromotionsButton = false,
  showAllPromotionsButtonText = 'Show all promotions',
  handleShowAllPromotion,
  onCreateCampaign,
}: Props) => {
  const [allServicesShown, setAllServicesShown] = useState<boolean>(false);

  const toggleAllServicesShown = () => setAllServicesShown(() => !allServicesShown);

  return (
    <div className={`qu-services-item qu-services-promotion-item`}>
      {showAllPromotionsButton
        ? <div className="qu-services-show-all-promotion">
          <QButton className="qu-button-outline" size="small" onClick={handleShowAllPromotion}>
            {showAllPromotionsButtonText}
          </QButton>
        </div>
        :
        <>
          <Row>
            <div className="qu-services-body qu-services-promotion-body">
              <Col>
                <div className="qu-services-thumbnail qu-services-promotion-thumbnail">
                  {
                    promotionThumbnail.length > 1
                      ? <Carousel autoplay>
                        {promotionThumbnail.map((image: any) =>
                          <img src={image} alt="thumbnail" key={image} />
                        )}
                      </Carousel>
                      : <img src={promotionThumbnail[0]} alt="thumbnail" />
                  }
                </div>
              </Col>
              <Col>
                <div className="qu-services-form">
                  <div className="qu-services-name">{promotionName.length > 75 ? promotionName.substring(0, 75) + '...' : promotionName}</div>
                  <div className="qu-services-description qu-services-date">
                    <span className="qu-services-type">{promotionDate}</span>
                  </div>
                </div>
              </Col>
            </div>
          </Row>

          {promotionType && !!promotionType.length &&
            <Row className="qu-services-promotion-content-body">
              <h2>
                {promotionTypeHeading}
                {promotionPrice && <span className="qu-services-type qu-services-custom-type status-label">
                  {promotionPrice}
                </span>}
              </h2>
              {
                // promotionType.length > 2 && !allServicesShown
                //   ?
                //   <ul className='mb-0'>
                //     {promotionType.slice(0, 2).map((ele: any) =>
                //       <Col span={24}>
                //         <li className='ml-16'>
                //           <div key={ele} className="qu-services-promotion-description">
                //             <span className="qu-services-type">{ele}</span>
                //           </div>
                //         </li>
                //       </Col>)}
                //   </ul>
                //   :
                <ul className='mb-0'>
                  {promotionType.map((ele: any) =>
                    <Col span={24}>
                      <li className='ml-16'>
                        <div key={ele} className="qu-services-promotion-description">
                          <span className="qu-services-type">{ele}</span>
                        </div>
                      </li>
                    </Col>)}
                </ul>
              }

              {/* {promotionType.length > 2 && <div className="qu-services-promotion-description cursor-pointer text-center qu-services-see-less-more-color" onClick={() => toggleAllServicesShown()}>
                {allServicesShown
                  ? "See Less Services"
                  : "See More Services"
                }
                {allServicesShown ? (
                  <IconsCommon.IconArrowUp className="ml-5" />
                ) : (
                  <IconsCommon.IconArrowDown className="ml-5" />
                )}
              </div>} */}

              {/* {promotionPrice && <Col span={24}>
                <div className="qu-services-promotion-description">
                  <span className="qu-services-type qu-services-custom-type">{promotionPrice}</span>
                </div>
              </Col>} */}

              {promotionFiles && !!promotionFiles.length && <Col span={24}>
                <div className="qu-services-promotion-description pt-5">
                  {promotionFiles.map((ele: any, index: number) =>
                    <Tag className='cursor-pointer' key={ele} onClick={() => window.open(ele, '_blank')} color={"default"}>
                      {/* {getFileDetailsFromURL(ele).truncatedFilename} */}
                      <LinkOutlined /> {promotionFilesText} {index + 1}
                    </Tag>
                  )}
                </div>
              </Col>}
            </Row>
          }

          <Row className='pt-8'>
            <Col span={24}>
              <QButton onClick={onCreateCampaign} className="qu-button-soft full-width tc-list__card-edit-button">
                {buttonText}
              </QButton>
            </Col>
          </Row>
        </>
      }
    </div>
  )
}

export default QPromotionsCard