import React from 'react'

const backIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
    />
  </svg>
)
export interface IProps {
  title?: string
  onClick?: () => void
}
const QBackLink = ({ title = 'Back', onClick = () => null }: IProps) => {
  return (
    <button onClick={onClick} type="button" className="button--link">
      <span className="button__icon">{backIcon}</span>
      <span>{title}</span>
    </button>
  )
}
export default QBackLink
