// Generated with util/create-component.js
import React from 'react'
import './styles.scss'

export interface Props {
  label?: string
  value: number
}

const ProgressLabel = ({ label, value }: Props) => (
  <div className={`ProgressLabel ${value === 100 ? 'completed' : ''}`}>
    <span className="ProgressLabel__value">{value}%</span>
    <span className="ProgressLabel__label">{label}</span>
  </div>
)

export default ProgressLabel

