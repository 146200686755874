import React from 'react'
import { Tooltip } from 'antd'

interface Props {
  file: any
  className?: string
  viewOnly?: boolean
  disableView?: boolean
  showRemoveButton?: boolean
  showPreview?: boolean
  viewText?: string
  removeText?: string
  onView?: (e: React.MouseEvent) => void
  onRemove?: (e: React.MouseEvent) => void
}

const StandardFileView = ({
  file,
  className = '',
  viewOnly = false,
  disableView = false,
  showRemoveButton = true,
  showPreview = true,
  viewText = 'View',
  removeText = 'Remove',
  onView = () => { },
  onRemove = () => { }
}: Props) => (
  <div className={`single-image_mini-container ${className}`} onClick={onView}>
    {showPreview && <img className="image" src={file?.url ?? ''} alt="" />}

    <div className="details_wrapper">
      {file?.name && (
        <Tooltip
          overlayClassName="qu-tooltip"
          placement="top"
          title={file.name}
        >
          <div className="title text-truncate">{file.name}</div>
        </Tooltip>
      )}

      <div className="actions">
        {!disableView && (
          <div onClick={onView} className="remove">
            {viewText}
          </div>
        )}

        {!viewOnly && showRemoveButton && (
          <div onClick={onRemove} className="remove">
            {removeText}
          </div>
        )}
      </div>
    </div>
  </div>
)
export default StandardFileView
