import React from 'react'
import { Badge, Popover } from 'antd'

import {
  IconBell,
  IconWarning,
  ApprovedIcon,
  IconCheckMark,
  IconClose
} from '../Icons/IconsCommon'

import { InfoOutlined } from '@ant-design/icons'

import './styles.scss'

const ICONS = {
  COMPLETED: <IconCheckMark />,
  REJECTED: <IconWarning />,
  APPROVED: <InfoOutlined />,
  ERROR: <IconClose />
}
export interface IProps {
  data: any
  onClick: (e: any, item: any) => void
}

const NotificationsList = ({ data = [], onClick }: IProps) => {
  return (
    <div className="notifications-menu-list">
      {!data.length && (
        <div className="notifications-empty">No new notifications</div>
      )}
      {data.map((item) => (
        <a
          className="notifications-menu-item notifications-menu-approved"
          key={item.key}
          onClick={(e: any) => onClick(e, item)}
        >
          <span
            className={`notifications-menu-image ${
              item.status ? item.status.toLowerCase() : ''
            }`}
          >
            {ICONS[item.status] || <InfoOutlined />}
          </span>
          <span className="notifications-menu-info">
            <div className="notifications-text">
              <div className="mr-5 font-weight-800">{item.title}</div>
              {item.description}
            </div>
            <span className="notifications-menu-date">{item.date}</span>
          </span>
        </a>
      ))}
    </div>
  )
}

export interface Props {
  className?: string
  notificationsData?: any
  badgeCount?: number
  onOpen?: (e: any, name?: string) => void
  onClick?: (e: any, item: any) => void
}
const QNotificationMenu = ({
  className = '',
  notificationsData = [],
  badgeCount = 0,
  onOpen,
  onClick
}: Props) => {
  return (
    <Popover
      className={`notifications-menu-popover ${className}`}
      overlayClassName="notifications-menu-wrapper"
      content={<NotificationsList data={notificationsData} onClick={onClick} />}
      title="Notifications"
      trigger="click"
      placement="bottomRight"
      getPopupContainer={(node: any) => node.parentNode}
    >
      <div className="notifications-menu" onClick={onOpen}>
        <Badge count={badgeCount}>
          <span className="notifications-menu-icon">
            <IconBell />
          </span>
        </Badge>
      </div>
    </Popover>
  )
}
export default QNotificationMenu
