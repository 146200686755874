// Generated with util/create-component.js
import React from 'react'
import '../ProfileSidebar.scss'
import QDisabledBlock from '../QDisabledBlock'
import QFinishedBlock from '../QFinishedBlock'
import QCurrentBlock from '../QCurrentBlock'
import { InfoCard } from '../../../index'
import QUnfinishedBlock from '../QUnfinishedBlock'
import { COLORS } from '../../../constants/colors'

export interface Props {
  currentStep: string
  title: string
  filledSteps: string[]
  propertiesOfSteps: string[][]
  steps: string[]
  filledPropertiesOfStep: boolean[]
  filledStepsPropertiesLength: number
  description: string
  unfinishedSteps: string[]
  titles: string[]
  completion: number
}

const ClientSidebar = ({
  title,
  completion,
  currentStep,
  steps,
  filledSteps,
  propertiesOfSteps,
  filledPropertiesOfStep,
  filledStepsPropertiesLength,
  description,
  unfinishedSteps,
  titles
}: Props) => {
  // const [progress, setProgress] = useState(completion)

  // const flattenPropertiesOfSteps = propertiesOfSteps.reduce(
  //   (acc, val) => [...acc, ...val],
  //   []
  // )

  // useEffect(() => {
  //   setProgress(
  //     Math.ceil(
  //       (filledStepsPropertiesLength / flattenPropertiesOfSteps.length) * 100
  //     )
  //   )
  // }, [filledStepsPropertiesLength])

  const getBlock = () => {
    return steps.map((step, index) => {
      if (step === currentStep) {
        return QCurrentBlock(
          titles[index],
          propertiesOfSteps[index],
          filledPropertiesOfStep,
          index + 1
        )
      }
      if (filledSteps.includes(step)) {
        return QFinishedBlock(titles[index])
      } else {
        if (unfinishedSteps.includes(step)) {
          return QUnfinishedBlock(titles[index])
        }
        return QDisabledBlock(titles[index], index + 1)
      }
    })
  }

  const getProgressColor = (progress: number) => {
    if (progress > 49 && progress < 100) return COLORS.COMPLETION_MEDIUM
    if (progress >= 100) return COLORS.COMPLETION_FULL

    return COLORS.COMPLETION_LOW
  }

  return (
    <div className="PartnerProfileSidebar">
      <InfoCard
        headerLeft={<h3>{title}</h3>}
        headerRight={
          <h3
            className="headerRight"
            style={{ color: getProgressColor(completion) }}
          >
            {`${completion}%`}
          </h3>
        }
      >
        <div className="hr8" />
        {getBlock()}
        <div className="hr8" />
        <div className="description">
          <p>{description}</p>
        </div>
      </InfoCard>
    </div>
  )
}

export default ClientSidebar
