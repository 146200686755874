import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'

const qnotification = (args: ArgsProps) => {
  const styles = {
    color: '#1E252B'
  }

  notification[args.type]({
    ...args,
    // @ts-ignore
    className: args?.language === 'ar' ? 'qu-notifications-ar' : 'qu-notifications',
    style: styles
  })
}

export default qnotification
