import React from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'

import './styles.scss'

export interface Props {
  className?: string
  colors?: object
  data?: any
  width?: number
  height?: number
  iconType?: string
  pieOuterRadius?: number
  legendAlignRight?: boolean
  isLabelShown?: boolean
}

const chartColors = [
  '#63A6C8',
  '#F7A57A',
  '#8FCBA1',
  '#F9E3A4',
  '#A0DCFF',
  '#F0A9AB',
  '#B489C5',
  '#5CC073',
  '#60676A'
]

const RADIAN = Math.PI / 180
const renderCustomizedLabel = (props) => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    isLabelShown,
  } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      style={{ fontSize: '20px', fontWeight: 600 }}
      // textAnchor={x > cx ? 'start' : 'end'}
      textAnchor="middle"
      dominantBaseline="central"
    >
      {isLabelShown ? `${(percent * 100).toFixed(0)}%` : ''}
    </text>
  )
}

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="qu-chart-tooltip">
        <ul className="tooltip-list">
          {payload.map((item, i) => {
            return (
              <li key={i} className="tooltip-list-item">
                <span className="name">{item.name}:</span>
                <span className="value">
                  <b>{item.value}</b>
                </span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return null
}

const renderLegend = (props) => {
  const { payload, colors } = props;

  return (
    <ul className="recharts-default-legend recharts-custom-legend" style={{ padding: '0px', margin: '0px', textAlign: 'left' }}>
      {
        payload.map((entry, index) => (
          <li key={index} className="recharts-legend-item legend-item-0" style={{ display: 'block', marginRight: '10px' }}>
            <svg className="recharts-surface" width="30" height="30" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}>
              <path fill={colors[index % colors.length]} className="recharts-symbols" transform="translate(16, 16)" d="M-16,-16h32v32h-32Z"></path>
            </svg>
            <span>{entry.value}</span>
          </li>
        ))
      }
    </ul>
  );
}

const QPieChartSimple = ({
  className = '',
  colors = chartColors,
  data,
  width = 300,
  height = 300,
  iconType = 'circle',
  pieOuterRadius = 120,
  legendAlignRight = false,
  isLabelShown = true,
}: Props) => {
  return (
    <div className={`qu-chart qu-chart-bar qu-chart-pie ${className}`}>
      <PieChart width={width} height={height} className={legendAlignRight ? 'recharts-custom-legend-wrapper' : ''}>
        {
          legendAlignRight
            ? <Legend
              wrapperStyle={{ color: '#6E8590', right: `-${pieOuterRadius + 50}px`, top: 'unset' }}
              layout="vertical"
              verticalAlign="middle"
              align="right"
              iconType={iconType}
              iconSize={30}
              content={(props) => renderLegend({ ...props, colors })}
            />
            : <Legend
              wrapperStyle={{ color: '#6E8590' }}
              verticalAlign="bottom"
              iconType={iconType}
            />
        }
        <Tooltip
          // @ts-ignore
          content={<CustomTooltip />}
        />
        <Pie
          data={data}
          cx={145}
          cy={130}
          labelLine={false}
          label={(props) => renderCustomizedLabel({ ...props, isLabelShown })}
          outerRadius={pieOuterRadius}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            // @ts-ignore
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  )
}
export default QPieChartSimple
