import React from 'react'

import './styles.scss'

export interface Props {
  className?: string
  request: any
  invoiceNode: React.ReactNode
  poNode: React.ReactNode
  bankTransferNode: React.ReactNode
  paymentStatus: any
  onCardClick?: (event: any) => void
}

const PartnerBillCardItem = ({
  className = '',
  request,
  invoiceNode,
  poNode,
  bankTransferNode,
  paymentStatus,
  onCardClick = (event: any) => {}
}: Props) => {
  return (
    <li className={`qu-request-card ${className}`} onClick={onCardClick}>
      <div className="request-card-header">
        <div className="request-card-description">
          <div className="request-card-image">
            {request.productImageUrl[0] && (
              <img src={request.productImageUrl[0]} alt="product-image" />
            )}
          </div>
          <div className="request-card-info">
            <div className="request-card-name">
              {request.productName || 'Unnamed Request'}
            </div>
            <div className="request-card-received">
              {request.lastApprovedDate}
            </div>
          </div>
        </div>
        {request.payment?.status && (
          <div className="request-card-status">
            <span
              className={`status-label ${
                paymentStatus[request.payment.status] &&
                paymentStatus[request.payment.status].classStyle
              }`}
            >
              {paymentStatus[request.payment.status] &&
                paymentStatus[request.payment.status].label}
            </span>
          </div>
        )}
      </div>
      <div className="request-card-body mb-24">
        <div className="request-card-column">
          <div className="request-card-label mb-8">PO</div>
          <div className="request-card-value">{poNode}</div>
        </div>
        <div className="request-card-column">
          <div className="request-card-label mb-8">Invoice</div>
          <div className="request-card-value">{invoiceNode}</div>
        </div>
        <div className="request-card-column">
          <div className="request-card-label mb-8">Bank Transfer</div>
          <div className="request-card-value">{bankTransferNode}</div>
        </div>
      </div>
    </li>
  )
}
export default PartnerBillCardItem
