import React from 'react'

import { Row, Col } from 'antd'
import './styles.scss'
import QButton from '../Buttons/QButton'

export enum BRIEF_STATUS {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PENDING_APPROVAL = 'PENDING APPROVAL',
  REJECTED = 'REJECTED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  NO_RESPONSE = 'NO_RESPONSE',
  PAID = 'PAID'
}

export interface Props {
  cardList: any
  labelStatus?: any
  dummyCampaignNameText?: string
  paymentStatus?: any
  isActiveCampaign?: boolean
  dateStartedText?: string
  dateCreatedText?: string
  startsText?: string
  promotionText?: string
  statusText?: string
  paymentStatusText?: string
  viewText?: string
  editText?: string
  deleteText?: string
  onViewClick: (id: number) => void
  onEditClick?: (id: number) => void
  onDeleteClick?: (id: number) => void
}

const QCampaignsTableCardList = ({
  cardList,
  labelStatus,
  dummyCampaignNameText = 'Unnamed Brief',
  paymentStatus,
  isActiveCampaign = false,
  dateStartedText = 'Started',
  dateCreatedText = 'Created',
  startsText = 'Starts',
  promotionText = 'Promotion',
  statusText = 'Status',
  paymentStatusText = 'Payment Status',
  viewText = 'View',
  editText = 'Edit',
  deleteText = 'Delete',
  onViewClick = () => { },
  onEditClick = () => { },
  onDeleteClick = () => { }
}: Props) => {

  const getCartLabel = (duration: any) => {
    if (!duration) return "QTY";
    let label = duration.split(" ")[1];
    return label.length > 5 ? label.substring(0, 4) + ".." : label
  }

  return (
    <>
      {
        cardList.map((card) =>
          <div className="qu-campaigns-card-item" key={card.key}>
            <Row>
              <div className="qu-campaigns-card-item-body">
                <Col>
                  <div className="qu-campaigns-card-item-thumbnail">
                    {card.product.productFirstImage && <img src={card.product.productFirstImage} alt="thumbnail" />}
                  </div>
                </Col>
                <Col>
                  <div className="qu-campaigns-card-item-form">
                    <div className="qu-campaigns-card-item-name">
                      {card.product.campaignName ? (card.product.campaignName.length > (card.product.mediaStartDate !== "Invalid date" ? 40 : 65) ? card.product.campaignName.substring(0, (card.product.mediaStartDate !== "Invalid date" ? 40 : 65)) + '...' : card.product.campaignName) : dummyCampaignNameText}
                    </div>

                    <div className="qu-campaigns-card-item-date">
                      {isActiveCampaign ? dateStartedText : dateCreatedText} {card.product.created}
                    </div>

                    {card.product.mediaStartDate !== "Invalid date" && <div className="qu-campaigns-card-item-date">
                      {startsText} {card.product.mediaStartDate}
                    </div>}

                    {card.product.discountText &&
                      <div className="qu-campaigns-card-item-date status-label qu-campaigns-card-item-discount-label">
                        {card.product.isPromotional && `[${promotionText}] `}{card.product.discountText}
                      </div>}
                  </div>
                </Col>
              </div>
            </Row>

            <Row className="qu-campaigns-card-item-center-body pt-5">
              {
                card.services &&
                !!card.services.length &&
                card.services.map((service: any) =>
                  <Col span={24} key={service.name} className="pt-5">
                    <div className="qu-campaigns-card-item-description">
                      <span className="service-name">{service.name}</span>
                      <span className="service-quantity">
                        {service.quantity.toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })} {service.durationText ? service.durationText : getCartLabel(service.duration)}
                      </span>
                    </div>
                  </Col>
                )
              }

              <Col span={24} className="pt-5">
                <div className="qu-campaigns-card-item-description">
                  <span className="service-name">
                    {statusText}
                  </span>

                  <span className="service-quantity">
                    <div className="tc-list__card-uBar">
                      <span
                        className={`status-label ${labelStatus[card.status.value] &&
                          labelStatus[card.status.value].classStyle
                          }`}
                      >
                        {labelStatus[card.status.value] &&
                          labelStatus[card.status.value].label}
                      </span>
                    </div>
                  </span>
                </div>
              </Col>

              {
                card?.paymentStatus &&
                <Col span={24} className="pt-5">
                  <div className="qu-campaigns-card-item-description">
                    <span className="service-name">
                      {paymentStatusText}
                    </span>

                    <span className="service-quantity">
                      <div className="tc-list__card-uBar">
                        <span
                          className={`status-label ${paymentStatus[card?.paymentStatus?.value] &&
                            paymentStatus[card?.paymentStatus?.value].classStyle
                            }`}
                        >
                          {paymentStatus[card?.paymentStatus?.value] &&
                            paymentStatus[card?.paymentStatus?.value].label}
                        </span>
                      </div>
                    </span>
                  </div>
                </Col>
              }
            </Row>

            <Row className="pt-10">
              {
                isActiveCampaign
                  ? <Col span={24}>
                    <QButton className="test-class full-width tc-list__card-view-button" type="primary" onClick={() => onViewClick(card.status.id)}>
                      {viewText}
                    </QButton>
                  </Col>
                  : <>
                    <Col span={8}>
                      <QButton className="test-class full-width tc-list__card-view-button" type="primary" onClick={() => onViewClick(card.status.id)}>
                        {viewText}
                      </QButton>
                    </Col>
                    <Col span={8}>
                      <QButton className="qu-button-soft full-width tc-list__card-edit-button" onClick={() => onEditClick(card.status.id)}>
                        {editText}
                      </QButton>
                    </Col>
                    <Col span={8}>
                      <QButton className="tc-list__card-button-red full-width tc-list__card-delete-button" onClick={() => onDeleteClick(card.status.id)}>
                        {deleteText}
                      </QButton>
                    </Col>
                  </>
              }
            </Row>
          </div>
        )
      }

      {/* old design */}
      {/* <div className="tc-list">
        {cardList.map((card) => {
          return (
            <div
              key={card.key}
              className="tc-list__card tc-list__campaigns_card"
              onClick={(event) => event.currentTarget == event.target && onViewClick(card.status.id)}
            >
              <div className="tc-list__card-header pl-15 pr-15">
                <div className="tc-list__card-header-info">
                  <h3 className="tc-list__card-title tc-list__card-campaigns-title">
                    {card.product.campaignName || 'Unnamed Brief'}
                  </h3>
                </div>
              </div>

              {
                card.services.map((service) =>
                  service.quantity && <div className="tc-list__card-header pl-15 pr-15">
                    <div className="tc-list__card-header-info">
                      <h3 className="tc-list__card-title tc-list__card-grey-title">
                        {service.title}
                      </h3>
                    </div>
                    <div
                      className="tc-list__card-uBar"
                    >
                      {service.quantity.toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </div>
                  </div>
                )
              }

              <div className="tc-list__card-header pl-15 pr-15">
                <div className="tc-list__card-header-info">
                  <h3 className="tc-list__card-title tc-list__card-grey-title">
                    Status
                  </h3>
                </div>
                <div
                  className="tc-list__card-uBar"
                >
                  <span
                    className={`status-label ${labelStatus[card.status.value] &&
                      labelStatus[card.status.value].classStyle
                      }`}
                  >
                    {labelStatus[card.status.value] &&
                      labelStatus[card.status.value].label}
                  </span>
                </div>
              </div>

              {card?.paymentStatus && <div className="tc-list__card-header pl-15 pr-15">
                <div className="tc-list__card-header-info">
                  <h3 className="tc-list__card-title tc-list__card-grey-title">
                    Payment Status
                  </h3>
                </div>
                <div
                  className="tc-list__card-uBar"
                >
                  <span
                    className={`status-label ${paymentStatus[card?.paymentStatus?.value] &&
                      paymentStatus[card?.paymentStatus?.value].classStyle
                      }`}
                  >
                    {paymentStatus[card?.paymentStatus?.value] &&
                      paymentStatus[card?.paymentStatus?.value].label}
                  </span>
                </div>
              </div>}

              <Row>
                <Col span={12}>
                  <QButton className="test-class full-width tc-list__card-view-button" type="primary" onClick={() => onViewClick(card.status.id)}>
                    View
                  </QButton>
                </Col>
                <Col span={12}>
                  <QButton className="qu-button-soft full-width tc-list__card-edit-button" onClick={() => onEditClick(card.status.id)}>
                    Edit
                  </QButton>
                </Col>
              </Row>
            </div>
          )
        })}
      </div> */}
    </>
  )
}

export default QCampaignsTableCardList
