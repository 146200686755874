import React from 'react'
import { Input } from 'antd'

import './styles.scss'

const QInput = ({ className = '', ...props }) => {
  return (
    <Input type='text' className={`qu-input ${className}`} {...props} />
  )
}
export default QInput
