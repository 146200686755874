import React from 'react'

import './styles.scss'

export interface Props {
  servicesName: string
  servicesPriseCalc: string
  totalValue: string
}

const QServicesItemView = ({
  servicesName,
  servicesPriseCalc,
  totalValue,
  ...props
}: Props) => {
  return (
    <div className="services-item-wrapper services-item-filled">
      <div className="services-item-name">{servicesName}</div>
      <div className="services-item-result">
        <div className="services-item-price">{servicesPriseCalc}</div>
        <div className="services-item-value">{totalValue}</div>
      </div>
    </div>
  )
}
export default QServicesItemView
